import React, { Component } from 'react';
import {InputText} from 'primereact/components/inputtext/InputText';
//import { Dropdown } from 'primereact/components/dropdown/Dropdown'
import {SelectButton} from 'primereact/components/selectbutton/SelectButton';
//import { ENGRAVING_TIMEOUT,ENGRAVING_URL } from '../constants';
import {filter} from '../reducers/utils'


class AiInputTextDia extends Component {
  constructor(props){

    super(props);
  //  this.inputText = React.createRef();
  let text =this.props.value
     
    
    
    this.state = { text:text };
    this.onChange = this.onChange.bind(this);  
   ////console.log('Dia CONSTR',this.props,this.state) //,this.state.text)
 
}

  componentDidUpdate(){
    
   // var newText =filter(this.props.value,'digits')
    //this.props.filter,this.props.maxLength)
    //var newText =this.props.value
    ////console.log('newText',newText,this.props.loading)
  
  //  if (newText !== this.state.text   && this.props.loading ==='new'  ) {
  //  this.setState({text : newText })
  //}
    
  ////console.log('TEXT DIDupdate',this.props,this.props.value,this.state.text,this.props.loading)
   
        if (this.props.type ==='combobox' && this.props.value !== this.state.text)  
        this.setState({text : this.props.value }) 
        if (this.props.type ==='input' && this.props.value !== this.state.text)  {
          this.setState({text : this.props.value }) 
       ////console.log('TEXT DIDupdateINPUT',this.props,this.state)
        
        }
        //else
        //this.setState({text : this.props.text }) 
        
}

onChange(e) { 
   ////console.log('DIA onchange(e)',this.state,this.props) 
   
  if (this.props.type ==='input') { 
    const newText = filter(e.target.value,'digits'); 
    if (newText !== this.state.text ) {
       this.setState({text : newText});  
   }
  ////console.log('DIA2 onchange(e)',this.state,e.target.value,this.state.text,newText) 
   
   this.props.onChange(newText)
  }
  else if (e.value !== null){
    
    this.setState({text: e.value}) 
    this.props.onChangeCombo(e.value.code)
  }
 ////console.log('onchange(e) end',this.state) 
  
}  
/*options={[
  { label: 'option1', value: 'option1' },
  { label: 'option2', value: 'option2' }
]}
value={this.state.dropdownValue}
onChange={(e) => {this.setState({dropdownValue: 'test-value'})}}
placeholder='Select...' />*/
  render() {
    //this.props.onLoading()
    ////console.log('RENDER TextInput',this.props.attr,this.props.value,'loading-',this.props.loading)
   //onChange={(e) => {this.setState ({len:this.getLength(e.target.value)});this.props.onChange(e.target.value)}}/>
    //this.setState ({len:this.props.value.length,text:filter(this.props.value)})
    ////console.log('renderInput',this.props)
    const menu = []
    for (var i in this.props.menu)
    {
      menu.push({name:this.props.menu[i],code:this.props.menu[i]})
    }
      
  
    let InputDia
    if (this.props.type === 'input') 
    InputDia =<InputText  style={{width:'200px'}}
    key={this.props.key}
    // ref="inputText"
    className='ai-inputtext'
    type="text" 
   // defaultValue={this.props.value}
    maxLength={this.props.maxLength} 
    //onChange={(e) => this.setState({text: e.value})}
    
    value={this.state.text}
    onChange={this.onChange}
    
   />
   else{
    ////console.log('value for combo',this.state)
   InputDia =<SelectButton  
   //dataKey={this.props.key}
   // ref="inputText"
   optionLabel="name" 
   className='ai-inputtext'
   options={menu}
   value={this.state.text}
   //value={menu[0]}
   //onChange={(e) => this.setState({text: e.value})}
   //type="text" 
   //defaultValue={this.state.text}
   //onFocus={()=>this.props.onLoading}
   //maxLength={this.props.maxLength} 
   
   //value={this.state.text}
   onChange={this.onChange}
   //onBlur={this.props.onBlur}  
  />
   }
  //style={{width:this.props.maxSliderSize4+'px'}}
  return (
    <div className={'ai-inputtext-box-dia '+this.props.show}   >
    <span className="ai-inputtext-wrap"  >
    <div className={"ai-dia "+this.props.diavalid}  >{this.props.name}</div>
    {InputDia}
    </span>
    </div>
    
    
);
  }
}
//export default connect()(AiInput);
export default AiInputTextDia;