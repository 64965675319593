import React, { Component } from 'react';
import AiImageBigWrap from './AiImageBigWrap';
import Sticky  from "react-sticky-el";
import AiOptionSetWrap from './AiOptionSetWrap';
import AiToolbarWrap from './AiToolbarWrap';
import {OPTION_URL} from '../constants';
import { FocusRing } from '@react-aria/focus';

//import {Button} from 'primereact/components/button/Button';
function callback(status) {
    ////console.log('callback status',status);
  }  
class MainBodyPortrait extends Component {
    
    render() {
        ////console.log('MAIN BODY PORTRAIT render',this.props.showSticky);
        return (<div  className={'ai-body' + (this.props.initial === 'yes' ? ' ai-init' : '')}> 
        <div className={'ai-cfg-small'}>SKU #<div>{this.props.cfg}</div></div>
        {/* <div className='ai-cfg-small'>Stock #{this.props.cfg}</div> */}
        <div className='ai-title-small'>{this.props.title}&nbsp;</div>
     
     
       <Sticky  topOffset={0}  className={'ai-sticky' + (this.props.initial === 'yes' ? ' ai-init' : '')} disabled={this.props.showSticky} 
       onFixedToggle={callback} > 
       <FocusRing focusRingClass="focus-ring">
       <button className='button2 ai-startover-small'
        onClick={this.props.onStartOver}>
        <img className="ai-startover-button" 
        src={OPTION_URL + "small/button/so3.png"} alt="Start Over"
        srcSet={OPTION_URL + "small/button/so3.png 1x, " +OPTION_URL + "large/button/so3.png 2x"}
        />
        </button>
        </FocusRing>
        <FocusRing focusRingClass="focus-ring">
       <button className='button2 ai-zoom-small'
        onClick={this.props.onPushOpenBox}>
        <img className="ai-zoom-button" 
        src={OPTION_URL + "small/button/z3.png"} 
        srcSet={OPTION_URL + "small/button/z3.png 1x, " +OPTION_URL + "large/button/z3.png 2x"}
        alt="Zoom"/>
        </button>
        </FocusRing>
        <FocusRing focusRingClass="focus-ring">
         <button className='button2 ai-mainzoom' onClick={this.props.onPushOpenBox}>
        <AiImageBigWrap 
        size={this.props.size} 
        className='ai-image-portrait' 
        orientation={this.props.orientation}/>
        </button>
       </FocusRing>


        <AiToolbarWrap className='ai-toolbar-panel' size={this.props.size}/>
        </Sticky>
        <div className='ai-all-options'>
            <AiOptionSetWrap 
            optionNumber={this.props.optionNumber} 
            optionSize={this.props.optionSize}
            orientation={this.props.orientation}/>
        </div>
        
        </div>
        );
    }
}


export default MainBodyPortrait;