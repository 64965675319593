export   function header_footer_get_parameters() {
let DEFAULT_SITE ='kay'    
//let DEFAULT_SITE ='zalesoutlet'    
//let DEFAULT_SITE ='zales'    
//let DEFAULT_SITE ='kayoutlet'    
//let DEFAULT_SITE ='peoplesjewellers'    
let availableEnv = {
    'localhost': ['localhost', 'dev1', 'dev2', 'dev3', 'dev4',
        'qa1', 'qa2', 'uat1', 'uat2', 'perf1', 'perf2',],
    'zales': ['bridal.', 'www','dev1', 'dev2', 'dev3', 'dev4',
        'qa1', 'qa2', 'uat1', 'uat2','uat3', 'perf1', 'perf2',],
    'peoplesjewellers': ['bridal.', 'dev1', 'dev2', 'dev3', 'dev4','www',
        'qa1', 'qa2', 'uat1', 'uat2', 'perf1', 'perf2',],
    'zalesoutlet': ['bridal.', 'dev1', 'dev2', 'dev3', 'dev4',
        'qa1', 'qa2', 'uat1', 'uat2', 'perf1', 'perf2',],
    'kayoutlet': ['prod2','bridal.', 'www','dev1','uat1', 'uat2'],
    'kay': ['prod2','bridal.','www', 'dev1', 'uat1', 'uat2','test'],
    'jared': ['prod2','bridal.','www', 'dev1','uat1', 'uat2','test']
};
let availableSitesUrl = {
    'localhost':DEFAULT_SITE+ '.com',
    'zalesoutlet': 'zalesoutlet.com',
    'zales': 'zales.com',
    'kayoutlet': 'kayoutlet.com',
    'kay': 'kay.com',
    'peoplesjewellers': 'peoplesjewellers.com',
    'jared': 'jared.com'
   
    
};


let availableSites = {
    'localhost':DEFAULT_SITE,
    'zalesoutlet': 'zalesoutlet',
    'zales': 'zales',
    'kay': 'kay',
    'peoplesjewellers': 'peoplesjewellers',
    'kayoutlet': 'kayoutlet',
    'jared': 'jared'
    
};
let availableSitesList = ['localhost',    'zalesoutlet',    'zales',    'kayoutlet',    'kay',    'peoplesjewellers',    'jared']


let siteNames = {
    'zales': 'Zales',
    'kay': 'Kay',
    'zalesoutlet': 'Zales Outlet',
    'peoplesjewellers': 'Peoples Jewellers',
    'kayoutlet': 'Kay Jewelers Outlet',
    'jared': 'Jared'
};


var localhostSite =DEFAULT_SITE;

//window.currentSite = localhostSite;
//window.currentEnv = "prod";
//window.currentSite = "kayoutlet";
window.currentEnv = "test";
window.currentSite = DEFAULT_SITE;
////console.log('availableSites.keys', Object.keys(availableSites))
//var all_sites = Object.keys(availableSites);
var all_sites = availableSitesList;
for (let i in all_sites) {
    let val = all_sites[i];
    ////console.log('val', val)
    if (window.location.hostname.includes(val)) {
        window.currentSite = availableSites[val];
        ////console.log('break available sites val', val,availableSites[val])
        break;
    }
}

////console.log('currentSite', window.currentSite,window.location.href);

var all_env = availableEnv[window.currentSite];
for (let i in all_env) {
    let val = all_env[i];
    ////console.log('try avail env val', val, all_env[i],window.location.href)
    if (window.location.href.includes(val)) {
        window.currentEnv = val;
        ////console.log('break avail env val', val)
        break;
    }
}
//if (window.currentEnv === 'personalized.')
//    window.currentEnv = 'prod';
////console.log('test currentEnv', window.currentEnv);
var calcUrl = window.currentEnv;
if (['zales', 'kay', 'jared','zalesoutlet', 'kayoutlet','peoplesjewellers'].indexOf(window.currentSite) !== -1 && calcUrl === 'bridal.') {
    calcUrl = 'www'
}
window.currentEnv = calcUrl
////console.log('currentEnv', window.currentEnv);

// for localhost testing
if (['localhost'].indexOf(window.currentSite) !== -1 && calcUrl === 'localhost') {
    calcUrl = 'uat2';
    window.currentSite = localhostSite
}

window.currentSiteUrl =   'https://' + calcUrl + '.' + availableSitesUrl[window.currentSite];
//console.log('currentSiteUrl', window.currentSite);
window.baseSiteUrl = window.currentSiteUrl.replace('prod.', 'www.');
////console.log('baseSiteUrl', window.baseSiteUrl);


// default ..title
switch (window.currentSite) {
    case "kay":
        document.title = "Engagement Rings, Wedding Rings, Diamonds, Charms. Jewelry from Kay Jewelers, your trusted Jewelry Store - Kay";
        break;
    case "jared":
        document.title = "Wedding Rings, Watches, Diamonds and more. Jared&reg; the Galleria of Jewelry, 5X the selection of Ordinary Jewelry Stores - Jared";
        break;
    default:
        document.title = "Bridal Customizer" //window.currentSite.charAt(0).toUpperCase() + window.currentSite.substr(1);
        break;
}


window.siteName = siteNames[window.currentSite];

// import css
switch (window.currentSite) {
    case "localhost":
        import('./site_interfaces/'+ localhostSite +'.css');
        break;
    default:
        import('./site_interfaces/' + window.currentSite + '.css');
        break;
}
}