import { connect } from 'react-redux'
import { state_update } from '../actions'
import AiOption from './AiOption'
import ReactGA from 'react-ga4';
import {state_load_bs } from '../actions'
const mapStateToProps = (state, ownProps) => ({
 // active: ownProps.value === state[ownProps.attr] ? true: false,
  //select: ownProps.value === state[ownProps.attr] ? 'ai-text-select ai-select':'ai-text-unselect',
  show1: state['hide_'+ownProps.attr+'-' + ownProps.value] !== true ? 'ai-show':'ai-hide',
 // select: ownProps.value === state[ownProps.attr] ? 'ai-text-select ai-select ai-select-'+state.currentSite :'ai-text-unselect',
 // selectText: ownProps.value === state[ownProps.attr] ? 'ai-color-'+state.currentSite:'ai-text-unselect',
 //  showReview: state.showReview,
  cur_ga_event: state.ga_event,
  store: state.store,
  isposterminal: state.isposterminal,
  imageversion: state.imageversion
 // css:ownProps.css
})


 

const mapDispatchToProps = (dispatch, ownProps) => ({
      onOpenBS: (() =>{ 
      /*  ReactGA.event({
          category: 'View Bestsellers',
          action:'View' 
        });*/
      }
      ),
      onClick: ( (state1) => { 
       //console.log('event selected',state1)   
      //dispatch(state_update({attr:ownProps.attr,value:ownProps.value}));
      dispatch(state_update({attr:'initial',value:''}));
      dispatch(state_load_bs(state1));
      /*ReactGA.event({
        category: 'Bestseller',
        action:state1.bestseller 
      });*/
      }
    )
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(AiOption)