
import {
    includeRule,
    excludeRule,
    hideOptionSet
  }
  from './rules_functions.js';

  
export function rules_headc(newState, state) {
    //rules with 4 conditions:
    var newrule = includeRule(newState, state, 'headc')
        ////console.log('ALL Include rules', newrule)
    var show  = hideOptionSet(newState, state, [ ['head', ['S1','P1','B1']]])
    if (show == true)  {
    newrule = excludeRule(newState, state, [
        ['ce', ['D','LD','UD']],
        ['ds', ['E', 'P']],
        ['head', ['T1TSTR']],
        ['headc', ['BDI']]], newrule)
    newrule = excludeRule(newState, state, [
        ['ce', ['D','LD','UD']],
        ['ds', ['R', 'C', 'O']],
        ['head', ['T1TSPR']],
        ['headc', ['BDI']]], newrule)
  
    
    }
    newrule['show']  = show
      return newrule
}