import 'react-app-polyfill/ie11';
import 'core-js'
//import "core-js/stable";
//bgimport "regenerator-runtime/runtime";
import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import AppWrap from './AppWrap';
import registerServiceWorker from './registerServiceWorker';


import { Provider } from 'react-redux'
import rootReducer from './reducers'

import {Router, Route} from 'react-router-dom';
//require("history").createBrowserHistory
//import createBrowserHistory from 'history/createBrowserHistory';
import { createBrowserHistory as createHistory } from 'history'
//import createBrowserHistory.createBrowserHistory from 'history';
import {listenForHistoryChange} from 'redux-location-state';
import createAppStore from './store';

//const history = createBrowserHistory();
const history = createHistory()
const store = createAppStore(rootReducer,history);

listenForHistoryChange(store, history);
    
ReactDOM.render(
  <Provider {...{store}}>
   <Router {...{history: history}}>
    <Route exact path="/" 
   render={() => <AppWrap/>}/>
    </Router>
    

  </Provider>
  , document.getElementById('root'));
registerServiceWorker();

