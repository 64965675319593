
import {
    includeRule,
    excludeRule,
    hideOptionSet
  }
  from './rules_functions.js';
  
  
export function rules_cec(newState, state) {
    var newrule = includeRule(newState, state, 'cec')
    
    var show =  hideOptionSet(newState, state, [ ['ce', ['D','LD']]])
    //console.log('dq rules show1',hide)
    if (show == true)  {
      show  = hideOptionSet(newState, state, [ ['ud', ['U']]])
    }
    //console.log('dq rules show2',hide)
    
    
   

    newrule['show']  = show
    return newrule
}