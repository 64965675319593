import { connect } from 'react-redux'
import { state_update } from '../actions'
import AiInputText from './AiInputText'
import ReactGA from 'react-ga4';

const mapStateToProps = (state, ownProps) => ({
  
  value: state[ownProps.attr],
  //loading:  state['loading-'+ownProps.product],
  loading:  state['loading'],
  smetal: state['smetc'],
  bmetal: state['bmetc'],
  product_type:ownProps.product,
  show: ownProps.show ? 'ai-show':'ai-hide',
  cur_ga_event: state.ga_event,
  maxSliderSize:ownProps.maxSliderSize < 390?ownProps.maxSliderSize.toString():'390',
  maxSliderSize4:ownProps.maxSliderSize < 390?(ownProps.maxSliderSize+4).toString():'394'
  
  })

 

const mapDispatchToProps = (dispatch, ownProps) => ({
  onLoadingDone: (() =>{ 
////console.log('onLoadingDone256',ownProps.product)
  //  dispatch(state_update({attr:'showSticky',value:true}) )
//    dispatch(state_update({attr:'loading-'+ownProps.product,value:'done'}) )
    dispatch(state_update({attr:'loading',value:'done'}) )
  }
  ),
  onChange: ( (value) =>{ 
    ////console.log('LOCAL onChange text',ownProps.attr,value)
    // var elem1 = document.getElementById("sti");
    // var style = window.getComputedStyle(elem1).getPropertyValue("position");
 ////console.log('onChange text', ownProps.attr,value); 

     dispatch(state_update({attr:ownProps.attr,value:value}) )
  }
  ),
  onBlur: ( () =>{ 
    dispatch(state_update({attr:'showSticky',value:true}  ))
    ////console.log('onBlur text')
  }
  ),
  onFocus:( (state) => { 
    dispatch(state_update({attr:'showSticky',value:false}  ))
   ////console.log('event engraving',state)
        
    ////console.log('onFocus text',ownProps.options_all.replace('e','E'))
    /*console.log('GA Test eng',ReactGA._currentMeasurementId) 
    if (state !== 'Engraving')
    ReactGA.event({
      category: 'Engraving',
      send_to:ReactGA._currentMeasurementI,
      action:ownProps.options_all.replace('e','[E]') 
  });*/
    dispatch(state_update({attr:'active_view',value:ownProps.switchView}  ))
    dispatch(state_update({attr:'ga_event',value:'Engraving'}))
      
    
  
})
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(AiInputText)