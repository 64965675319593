
import {
    includeRule,
    excludeRule,
    hideOptionSet
  }
  from './rules_functions.js';
  
  
export function rules_ce(newState, state) {
    var newrule = includeRule(newState, state, 'ce')
    

    var show =  hideOptionSet(newState, state, [ ['ud', ['U']]])
 //   newrule = excludeRule(newState, state, [
 //       ['ud', ['U']],
 //       ['ce', ['D','C']]], newrule)  
   

    newrule['show']  = show
    return newrule
}