import React, {Component} from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import {Accordion, AccordionTab} from 'primereact/components/accordion/Accordion';


export let MyMixin = (myclass) => class extends myclass {
    toogleWidth = 1310;
    isMobileLayout = false;
    styleHidden = {};

    render() {
        this.isMobileLayout = window.innerWidth < this.toogleWidth;
        if (this.isMobileLayout)
            this.styleHidden = {display: 'none'};

    }
};


export default class Footer extends Component {

    static defaultProps = {
        model: null,
        uiGridClasses: "ui-g-12 ui-md-6 ui-lg-2 ",
        isSmallSize: false
    };

    static propTypes = {
        model: PropTypes.array,
        uiGridClasses: PropTypes.string,
        isSmallSize: PropTypes.bool
    };


    itemClick(event, item) {
        if (item.disabled) {
            event.preventDefault();
            return;
        }

        if (!item.url) {
            event.preventDefault();
        }

        if (item.command) {
            item.command({
                originalEvent: event,
                item: item
            });
        }
    }

    renderFooterItem(item, index) {
        const itemClassName = classNames('footer-item', item.className)
        const iconClassName = classNames('footer-icon', item.icon)
        return (
            <div key={item.label + '_' + index} className={itemClassName} style={item.style}>
                <a className={'ui-widget'} href={item.url} target={item.target}
                   onClick={(event) => this.itemClick(event, item)}>
                    <span className={iconClassName}/>
                    <span>{item.label}</span>
                </a>
            </div>
        )
    }

    renderAccordionItem(props) {
        return (
            <AccordionTab key={props.item.label} header={props.item.label}>
                {props.item.items &&
                <div>
                    {props.item.items.map((item, index) => {
                        return this.renderFooterItem(item, index);
                    })}
                </div>
                }
            </AccordionTab>
        )
    }

    renderGridItem(props) {
        const className = classNames('footer-section', this.props.uiGridClasses)
        return (
            <div key={props.item.label} className={className}>
                <div className={'footer-category'}>{this.renderFooterItem(props.item, props.index)}</div>
                <div className={'footer-spliter'}></div>
                {props.item.items &&
                <div>
                    {props.item.items.map((item, index) => {
                        return this.renderFooterItem(item, index);
                    })}
                </div>
                }
            </div>
        )
    }

    renderAccordion() {
        return (
            <div className={'accordion-content footer'}>
                <Accordion>
                    {this.props.model.map((item, index) => {
                            return this.renderAccordionItem({item: item, index: index})
                        }
                    )}
                </Accordion>
            </div>
        )
    }

    renderGrid() {
        return (
            <div className="ui-g footer">
                {this.props.model.map((item, index) => {
                        return this.renderGridItem({item: item, index: index})
                    }
                )}
            </div>
        )
    }

    render() {
        if (this.props.isSmallSize) {
            return this.renderAccordion()
        }
        return this.renderGrid()
    }
}
