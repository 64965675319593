export function includeRule(newstate, state, rule) {
    var attrLast = []
    if (state.hasOwnProperty('options_' + rule))
        attrLast = state['options_' + rule]
            if (newstate.hasOwnProperty('options_' + rule))
                attrLast = newstate['options_' + rule]
  
                    for (let j in attrLast.options) { {
                            attrLast.options[j].show = true
                        }
                    }
                    return attrLast
  
  }
  
export function excludeRule(newstate, state, rule, allstate) {
////console.log('ALL NEW STATE', newstate, state, rule)
var condition = true
    var attr = []
    for (let i in rule) {
        ////console.log('ALL', i, rule.length - 1, rule)
        if (i < rule.length - 1) {
            ////console.log('ALL start', i, rule[i], rule.length)
            var attr1 = '?'
                if (state.hasOwnProperty(rule[i][0]))
                    attr1 = state[rule[i][0]]
                        if (newstate.hasOwnProperty(rule[i][0]))
                            attr1 = newstate[rule[i][0]]
                                attr.push(attr1)
                                if (rule[i][1].includes(attr1) !== true)
                                    condition = false
                                        ////console.log('ALL2', attr, attr1, condition)
        }
    }

    var last = rule.length - 1
    var attrLast = allstate
    ////console.log('Rules', attr, condition, attrLast, last, rule)
    if (attrLast !== undefined && condition) {
        for (let j in attrLast.options) {
            if (rule[last][1].includes(attrLast.options[j].value)) {
                attrLast.options[j].show = false
                    ////console.log('updated false', attrLast)
            }
        }
        return attrLast
    }
    return attrLast

}
export function hideOptionSet(newstate, state, rule) {
    var condition = true
        var attr = []
        for (let i in rule) {
            if (i < rule.length) {
                ////console.log('ALL start', i, rule[i], rule.length)
                var attr1 = '?'
                if (state.hasOwnProperty(rule[i][0]))
                        attr1 = state[rule[i][0]]
                //console.log('attr1', rule[i], attr1)
                if (newstate.hasOwnProperty(rule[i][0]))
                        attr1 = newstate[rule[i][0]]
                //console.log('attr2', rule[i], attr1)
                if (rule[i][1].includes(attr1)){
                //        console.log('attr3', rule[i], attr1)
                
                        condition = false
                        break
                        }
                                            ////console.log('ALL2', attr, attr1, condition)
            }
        }
    
    return condition    
    
    }
    
/*export function check_default(newrule) {
    var first_val = '?'
    for (let i in newrule.options) {
        if (newrule.options[i].show === true) {
            first_val = newrule.options[i].value
                break
        }
    }
    return first_val
}*/
export function set_default(newstate, state,name) {
    var newrule= newstate['options_'+name]
    //console.log('set0',name,newrule)
   
    var value = '?'
    if (state.hasOwnProperty(name))
    value = state[name]
    if (newstate.hasOwnProperty(name))
    value = newstate[name]
    var first_value = '?'
 
    for (let i in newrule.options) {
        if (newrule.options[i].show === true) {
            first_value = newrule.options[i].value
            break
        }
    }
   //console.log('set',name,newrule,first_value)
    if (newrule.show=== false && name !== 'dctw' && name !== 'dq')
        return first_value
    
for (let i in newrule.options) {
  //  console.log('set1-',i,name,newrule.options[i].show,newrule.options[i].value,value)
        
    if (newrule.options[i].value === value && newrule.options[i].show === true){
       // console.log('set1',name,newrule.options[i].show,newrule.options[i].value,value)
        return value
    }
}

//console.log('set2',name,newrule.show,first_value,value)
return first_value
}