import { connect } from 'react-redux'
import { state_update } from '../actions'
import AiInputTextDia from './AiInputTextDia'
//import ReactGA from 'react-ga';
function checkValid(val,ownProps,valreal) {
  ////console.log('checkValid',ownProps)
 if (val !== false) {return false}
  
 if (['dc','dcl'].includes(ownProps.attr))
    if (valreal=== '') {return true}  else {return false};
 if (['dw','dd','dl'].includes(ownProps.attr))
    if (Number.isNaN(parseFloat(valreal)) === true || parseFloat(valreal)< 3 || parseFloat(valreal) > 12) {return true}  else {return false};
 if (['dct'].includes(ownProps.attr))
    if (Number.isNaN(parseFloat(valreal)) === true || parseFloat(valreal)< 0.23 || parseFloat(valreal) > 3.11) {return true}  else {return false};
 
}
const mapStateToProps = (state, ownProps) => ({
  value: ownProps.type === 'input'? state[ownProps.attr]:{'name':state[ownProps.attr],'code':state[ownProps.attr]},
  loading:  state['loading-'+ownProps.product],
  show: ownProps.show ? 'ai-show':'ai-hide',
  diavalid: checkValid(state['valid-'+ownProps.attr],ownProps,state[ownProps.attr])?'dia-valid-false':'',
  type:ownProps.type,
   cur_ga_event: state.ga_event,
  maxSliderSize:ownProps.maxSliderSize < 300?ownProps.maxSliderSize.toString():'300',
  maxSliderSize4:ownProps.maxSliderSize < 300?(ownProps.maxSliderSize+4).toString():'304'
  
  })

 

const mapDispatchToProps = (dispatch, ownProps) => ({
  onLoadingDone: (() =>{ 
    ////console.log('onLoadingDone')
  //  dispatch(state_update({attr:'showSticky',value:true}) )
    /////dispatch(state_update({attr:'loading-'+ownProps.product,value:'done'}) )
    
  }
  ),
  onChange: ( (value) =>{ 
    ////console.log('LOCAL onChange text',ownProps.attr,value)
    // var elem1 = document.getElementById("sti");
    // var style = window.getComputedStyle(elem1).getPropertyValue("position");
    
    dispatch(state_update({attr:ownProps.attr,value:value}) )
    var tryMM = parseFloat(value)
    if (['dl','dd','dw'].includes(ownProps.attr) && (Number.isNaN(tryMM) === true  || tryMM < 3 ||tryMM > 12))dispatch(state_update({attr:'valid-'+ownProps.attr,value:false}))
    else dispatch(state_update({attr:'valid-'+ownProps.attr,value:true}))

    if (ownProps.attr === 'dct'){
      var newCTW = ''
      var tryCTW = parseFloat(value)
      if (tryCTW < 0.29) {newCTW= "0.25"}
      else if (tryCTW < 0.37) {newCTW= "0.33"}
      else if (tryCTW < 0.58) {newCTW= "0.5"}
      else if (tryCTW < 0.8) {newCTW= "0.75"}
      else if (tryCTW < 1.115) {newCTW= "1"}
      //else if (tryCTW < 1.29) {newCTW= "1.25"}
      else if (tryCTW < 1.58) {newCTW= "1.5"}
      else if (tryCTW < 2.11) {newCTW= "2"}
      else if (tryCTW < 2.58) {newCTW= "2.5"}
      else  {newCTW= "3"}
      //console.log('onChange text', ownProps,value,newCTW); ; 
    
      dispatch(state_update({attr:'dctw',value:newCTW})) 
      if (Number.isNaN(tryCTW) === true  || tryCTW < 0.23 ||tryCTW > 3.11)dispatch(state_update({attr:'valid-dct',value:false}))
      else dispatch(state_update({attr:'valid-dct',value:true}))
    }          
  }
  ),
  onChangeCombo: ( (value) =>{ 
    ////console.log('LOCAL onChange text',ownProps.attr,value)
    // var elem1 = document.getElementById("sti");
    // var style = window.getComputedStyle(elem1).getPropertyValue("position");
     ////console.log('onChangeCombo', ownProps,value);  
     //if (ownProps.attr === 'dc' && value=== '') dispatch(state_update({attr:'valid-dc',value:false}))
     //else dispatch(state_update({attr:'valid-dc',value:true}))
     //if (ownProps.attr === 'dcl' && value=== '') dispatch(state_update({attr:'valid-dc',value:false}))
     //else dispatch(state_update({attr:'valid-dc',value:true}))
    dispatch(state_update({attr:ownProps.attr,value:value}) )
    if (ownProps.attr === 'dcl'){
      if (value === 'I1'){
        dispatch(state_update({attr:'dq',value:"I"}))
      }
      else if (["SI2", "SI1"].includes(value)){
        dispatch(state_update({attr:'dq',value:"SI"}))
      }
      else {
        dispatch(state_update({attr:'dq',value:"VS"}))
      }
    }  
  /*  if (ownProps.attr === 'dcl1'){
      var newDQ = ''
      if (["I1"].includes(value))
        newDQ='I'
      else if (["SI2","SI1"].includes(value))
        newDQ='SI'
      else if (["VS2","VS1","VVS2","VVS1","IF","FL"].includes(value))
        newDQ='VS'
    //  dispatch(state_update({attr:'dq',value:newDQ}) )
    }  */
  }
  )
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(AiInputTextDia)