import ZalesHeader from "./zales"

class PeoplesJewelersHeader extends ZalesHeader {
    logoSrc = 'https://static.thepersonalizedbest.com/header/Peoples-logo.png'
    simpleBanner = {
        href: "https://www.peoplesjewellers.com/the-peoples-promise?icid=HMP:MINI_CART:FREESHIP_199",
        title: "Free shopping every day",
        imgSrc: "https://www.pagoda.com/images/hf3/h01/9652364673054.gif"
    };
}

export default PeoplesJewelersHeader
