import {Component} from "react";
import React from "react";
import ZalesFooter from "./zales"
import KayFooter from "./kay"
import JaredFooter from "./jared"
import PeoplesJewelersFooter from './peoplesjewelers'
import ZalesOutletFooter from "./zalesoutlet";
import KayOutletFooter from "./kayoutlet";

class FooterSwitchComponent extends Component {
    constructor(props) {
        super(props);
        this.state = {items: []}

    }

    componentDidMount() {
        var footer_url='/' + window.currentSite + '.footer.json'
        /*if (['kay','jared','kayoutlet'].includes(window.currentSite)) {
            footer_url = '/' + window.currentEnv + footer_url
        }*/
        fetch(footer_url)
            .then(response => {
                if (response.ok) {
                    return response.text()

                }
                else {
                    this.props.messages.show({
                        severity: 'error',
                        sticky: true,
                        summary: 'Error loading navbar data file: ',
                        detail: 'Cannot load data'
                    });
                    throw new Error('Something went wrong ...');
                }
            })
            .then(response => {
                try {
                    response = response.replace(new RegExp('url": "/', 'gi'), 'url": "' + window.baseSiteUrl + '/');
                    return JSON.parse(response);
                } catch (err) {
                    this.props.messages.show({
                        severity: 'error',
                        sticky: true,
                        summary: 'Error loading navbar data file: ',
                        detail: err.message
                    });
                }
            })
            .then(data => {
                for (var x in data ){
                    for (var x1 in data[x].items ){
                        var src1 ='www.'+ window.currentSite +'.com'
                        var dst1 = window.currentEnv+'.'+ window.currentSite +'.com'
                        if (window.currentEnv !== "prod")
                           data[x].items[x1].url =data[x].items[x1].url.replace(src1,dst1)
                        //console.log('item',data[x].items[x1])
    
                    }
                    
                };
                this.setState({items: data})})
    }


    render() {
        let Component = null;
        if (this.state && this.state.items) {
            let footerComponents = {
                kay: KayFooter,
                zales: ZalesFooter,
                jared: JaredFooter,
              
                peoplesjewellers: PeoplesJewelersFooter,
                zalesoutlet: ZalesOutletFooter,
               
                kayoutlet: KayOutletFooter
            };
            Component = footerComponents[window.currentSite];
        }

        return (
            <div>
                <Component model={this.state.items}/>
            </div>

        )
    }
}

export default FooterSwitchComponent
