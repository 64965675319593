import {Component} from "react";
import Footer from "../footer";
import React from "react";


class JaredFooter extends Component {
    toogleWidth = 760;
    isMobileLayout = false;

    render() {
        let styleHidden = {};
        this.isMobileLayout = window.innerWidth < this.toogleWidth;
        if (this.isMobileLayout)
            styleHidden = {display: 'none'};
        return (
            <div className="jared-footer">
                <hr/>
                <div className="ui-g">
                    {this.isMobileLayout &&
                    <div className="footer-email-signup1">
                        <ul className="right-block">
                            
                        </ul>
                    </div>
                    }
                    
                    
                  <div className={!this.isMobileLayout ? "ui-grid-col-8" : "ui-grid-col-12"}>
                       
                   <Footer model={this.props.model} isSmallSize={window.innerWidth < this.toogleWidth}
                                uiGridClasses="ui-g-12 ui-md-4 ui-lg-3" style={{width: '24%', backgroundColor: "#000"}}/>
                       
                    </div>
                    <div className="ui-g-2 ui-md-2 ui-lg-2" style={styleHidden}>
                                    <div style={{marginTop: '20px'}}><div className="footer-category">
                                        <div className="footer-item">
                                            <a className="ei-widget"><span style={{fontSize:'0.7rem'}}>FOLLOW US</span></a>
                                        </div>
                                        </div>
                                        <div className="footer-spliter"></div>
                                    </div>
                                    <ul className="footer-social-icons">
                                        <li style={{marginTop: "8px"}}>
                                            <a href="https://www.facebook.com/JaredTheGalleriaOfJewelry">
                                                <img alt="" src="/img/social_icons/pinterest.png" style={{display: 'inline', width: '41px', height: '41px', marginLeft: '-15px' }}/></a></li>
                                        <li style={{marginTop: "8px"}}>
                                            <a href="https://twitter.com/ThatsJared">
                                                <img alt=""  src="/img/social_icons/twitter.png" style={{display: 'inline', width: '41px', height: '41px'}}/></a></li>
                                        <li style={{marginTop: "8px"}}>
                                            <a href="https://www.youtube.com/thatsjared">
                                                <img alt="" src="/img/social_icons/youtube.png" style={{display: 'inline', width: '41px', height: '41px', marginLeft: '-15px'}}/></a></li>
                                        <li style={{marginTop: "8px"}}>
                                             <a href="https://www.pinterest.com/thatsjared">
                                                <img alt="" src="/img/social_icons/pinterest.png" style={{display: 'inline', width: '41px', height: '41px'}}/>
                                             </a>   
                                        </li>
                                        <li style={{marginTop: "8px"}}><a href="https://www.instagram.com/jaredthegalleriaofjewelry/">
                                                <img alt="" src="/img/social_icons/insta.png" style={{display: 'inline', width: '41px', height: '41px', marginLeft: '-15px'}}/> 
                                            </a>
                                        </li>
                                        </ul>
                    </div>
                    <div>
                        {this.isMobileLayout?
                                <div style={{margin: "auto", width: "100%"}}>
                                 <ul className="footer-social-icons-1">
                                        <li style={{marginTop: "8px"}}>
                                            <a href="https://www.facebook.com/JaredTheGalleriaOfJewelry">
                                                <img alt="" src="/img/social_icons/pinterest.png" style={{display: 'inline', width: '41px', height: '41px' }}/></a></li>
                                        <li style={{marginTop: "8px"}}>
                                            <a href="https://twitter.com/ThatsJared">
                                                <img alt=""  src="/img/social_icons/twitter.png" style={{display: 'inline', width: '41px', height: '41px'}}/></a></li>
                                        <li style={{marginTop: "8px"}}>
                                            <a href="https://www.youtube.com/thatsjared">
                                                <img alt="" src="/img/social_icons/youtube.png" style={{display: 'inline', width: '41px', height: '41px'}}/></a></li>
                                        <li style={{marginTop: "8px"}}>
                                             <a href="https://www.pinterest.com/thatsjared">
                                                <img alt="" src="/img/social_icons/pinterest.png" style={{display: 'inline', width: '41px', height: '41px'}}/>
                                             </a>   
                                        </li>
                                        <li style={{marginTop: "8px"}}><a href="https://www.instagram.com/jaredthegalleriaofjewelry/">
                                                <img alt="" src="/img/social_icons/insta.png" style={{display: 'inline', width: '41px', height: '41px'}}/> 
                                            </a>
                                        </li>
                                        </ul>
                                </div>: null
                    }</div>
                </div>
                
                <div className="footer-copyright-row__wrapper">
                    <div className="footer-copyright-row clearfix" style={{margin: "auto",textAlign:"center"}}>
                        <p></p>
                        <div>
                        <ul style={!this.isMobileLayout ? {display: 'flex',marginTop: '10px'} : {}}>
                            <li style={{fontSize: '10px', marginTop: '10px'}}>©JARED.COM</li>
                            <li style={{fontSize: '10px', marginTop: '10px'}}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<img alt="" src="https://www.jared.com/_ui/v0052/responsive/theme-jared/images/RJC_Logo.jpeg"
                             style={{display: 'inline', width: '22px', height: '32px'}}/> </li>
                            <li style={{ fontSize: '10px', marginTop: '10px'}}><a className="hero__link " href="https://www.jared.com/privacy-policy"
                                   target="_top" title="Privacy Policy"> Privacy Policy</a></li>
                            <li style={{fontSize: '10px', marginTop: '10px'}}><a className="hero__link "
                                   href="https://www.jared.com/jared-legal-disclaimers" target="_top"
                                   title="Terms of Use">&nbsp;Terms of Use </a></li>
                            <li style={{fontSize: '10px', marginTop: '10px'}}><a className=""
                                   href="https://www.jared.com/sitemap"
                                   target="_top">&nbsp;&nbsp;&nbsp;&nbsp;Site Map </a></li>
                            
                        </ul>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default JaredFooter;