import React, { Component } from 'react';
import Slider from "react-slick";
import AiOptionButtonWrap from './AiOptionButtonWrap';
//import { Dialog } from 'primereact/components/dialog/Dialog';
import { Button } from 'primereact/components/button/Button';
import {OPTION_URL,DYNAMIC_OPTION_URL6} from '../constants';
//import { stripBasename } from 'history/PathUtils';
/* const best10={ "K":[ "2DS3ELQXVC80A" , "2DSZWV9KSJT0A" , "2DVA3YJZ12M0A" , "2DVOUZLDWKU0B9P" , "2DS3D7RR3CB0B9P" , "2DSZW4GKL8O0XF" , "2DSZ6T2RKIX0B9P" , "2DS3ELY82RO0B9P" , "2DS3ELQ7XSK0B9P" , "2DS3ELZQ7JV0B9P" ],
"J":[ "2CSEIQSZ30A" , "2BO1DP7O0A" , "2CKHRBSYPH0A" , "2CX9SC6T8P0B9P" , "2CRZJKZY60B9P" , "2BV8DJVJ0XF" , "2KMTKH6S0B9P" , "2CSEQ1ZFJ0B9P" , "2CSEIZVGF0B9P" , "2CSERJ47Q0B9P" ],
"Z":[ "2HCSZSY5XPD0A" , "2HCQI4HRUVY0A" , "2HE1O6R74ER0A" , "2HFFG7TKYWZ0B9P" , "2HCSZEZY5OG0B9P" , "2HCQJBOROKT0XF" , "2HCQR2AYMU30B9P" , "2HCSZS8F44T0B9P" , "2HCSZSZEZ5P0B9P" , "2HCSZS8X9V10B9P" ]} */
const best10={ "K":[ "2DS3ELQXVC80A" , "2DSZWV9KSJT0A" , "2DVA3YJZ12M0A" , "2DVOUZLDWKU0B9P" , "2DS3D7RR3CB0B9P" , "2DSZW4GKL8O0XF" , "2DSZ6T2RKIX0B9P" , "2DS3ELY82RO0B9P" , "2DY1EXBESYA0H7Z" , "2DS3ELZQ7JV0B9P" ],
"J":[ "2CSEIQSZ30A" , "2BO1DP7O0A" , "2CKHRBSYPH0A" , "2CX9SC6T8P0B9P" , "2CRZJKZY60B9P" , "2BV8DJVJ0XF" , "2KMTKH6S0B9P" , "2CSEQ1ZFJ0B9P" , "2GASP27QL50H7Z" , "2CSERJ47Q0B9P" ],
"Z":[ "2HCSZSY5XPD0A" , "2HCQI4HRUVY0A" , "2HE1O6R74ER0A" , "2HFFG7TKYWZ0B9P" , "2HCSZEZY5OG0B9P" , "2HCQJBOROKT0XF" , "2HCQR2AYMU30B9P" , "2HCSZS8F44T0B9P" , "2HIQZ5JLVBF0H7Z" , "2HCSZS8X9V10B9P" ],
}
function NextArrow(props) {
  const { className, onClick } = props;
  return (
    <div
      className={className +' fa ai-next-arrow ai-fcolor-' + window.currentSite}
      onClick={onClick}
    />
  );
}
function PrevArrow(props) {
  const { className,  onClick } = props;
  return (
    <div
      className={className +' fa ai-prev-arrow ai-fcolor-' + window.currentSite}
      onClick={onClick}
    />
  );
}


class AiOption extends Component {
  constructor(props) {
    ////console.log('toolbar constr') 
     super(props);
 
     this.state = {
       visibleDialog: false
     };

     this.updateDimensions = this.updateDimensions.bind(this);
   }

   componentWillUnmount() {
       window.removeEventListener("resize", this.updateDimensions);
   }
   componentDidMount() {
       window.addEventListener("resize", this.updateDimensions);
   }

   updateDimensions() {
      this.setState({ visibleDialog: false })
   }

    // extends React.Component {
  render() {
    
    var settings = {
      dots: false,
      infinite: false,
      speed: 500,
      //lazyLoad: true,
      slidesToShow: this.props.optionNumber,
      slidesToScroll: this.props.optionNumber,
      arrow:true,
      initialSlide:0,
      nextArrow: <NextArrow />,
      prevArrow: <PrevArrow />,  
      className:'ai-options'
//      adaptiveHeight: true

    };
   // console.log('render-aioption',this.props)
    var optionSize = this.props.optionSize +'px';
    let rows = [];
    ////console.log('not last',this.props.last)
    let dynamic= ['ctw','shank','rset','bset','shape']
    var children = this.props.children
    for (let i in children) {
     // console.log('child',this.props.image_base,children[i])
      let image_url;
      let image_url2;
    if (dynamic.indexOf(this.props.image_base)!== -1){
          //  console.log('url',children[i])
        image_url = ''  
       
        
     
         if (this.props.image_base ==='shank') {
            var dctwimg =this.props.dctw
            if (dctwimg ==='2.5' || dctwimg ==='3' )
               dctwimg ='2' 
           // if (window.testAB==='B'){
            var rset='0'
            //console.log('sh',children[i].value )
            if (children[i].value==='4') rset='UP'
            else if (children[i].value==='5') rset='BD'
            else if (children[i].value==='2') rset='PR'
            else if (children[i].value==='11') rset='BD'
            else if (children[i].value==='8') rset='PV'
                
            image_url = OPTION_URL  +'large/shank/shank-'+ this.props.ds + '-'+ dctwimg + '-'+ this.props.head + '-'+ rset + '-' + children[i].image     
          //  }
          ///  else
          //  image_url += 'shank-'+ this.props.ds + '-'+ dctwimg + '-'+ this.props.head + '-' + children[i].image     
         } 
   
 //pkb-O-0.25-TH2-1-R-0.png?s=140

if (this.props.image_base ==='shank'){
    
    image_url2 = image_url+'?s=240'
    image_url = image_url+'?s=120'
  } 
  else  if (this.props.image_base ==='ctw'){
    
    image_url2 = OPTION_URL  +'large/'+this.props.image_base + '/'+ this.props.ds.toLowerCase() +'/'+children[i].image
    image_url =  OPTION_URL  +'small/'+this.props.image_base + '/'+ this.props.ds.toLowerCase() +'/'+children[i].image
  } 
  else  if (this.props.image_base ==='rset'){
    
    image_url2 = OPTION_URL  +'large/side/side-'+  this.props.sh + '-' + children[i].image 
    image_url =  OPTION_URL  +'small/side/side-'+  this.props.sh + '-' + children[i].image 
  } else  if (this.props.image_base ==='bset'){
    
    image_url2 = OPTION_URL  +'large/bside/side-'+  this.props.sh + '-' + children[i].image 
    image_url =  OPTION_URL  +'small/bside/side-'+  this.props.sh + '-' + children[i].image 
  } 
else  if (this.props.image_base ==='shape'){
    
    image_url2 = OPTION_URL  +'large/'+this.props.image_base +'/'+children[i].image
    image_url =  OPTION_URL  +'large/'+this.props.image_base +'/'+children[i].image
  } 
 else if (this.props.image_base !=='band')
    image_url2 = image_url+'?s=160'
 if (this.props.image_base ==='band' && children[i].image !=='0') {
           image_url2 = image_url+'?s=160'
           image_url = image_url+'?s=80'
           
         }
         
  }
      else {
      image_url = OPTION_URL  
      image_url2 = OPTION_URL  
      image_url += 'small/'
      image_url2 += 'large/'
      image_url += this.props.image_base +"/"
      image_url2 += this.props.image_base +"/"
      if (this.props.image_postfix !== ''){
        if (this.props.image_postfix ===  'ds') {
          image_url += this.props.ds +'/'
          image_url2 += this.props.ds +'/'
  
        } else {
          image_url += this.props.image_postfix +'/'
          image_url2 += this.props.image_postfix +'/'
  
        }
      }  
      image_url += children[i].image
      image_url2 += children[i].image
      }
      if (children[i].value === this.props.initialValue) 
        if (parseInt(i,10) >this.props.optionNumber) {
          settings.initialSlide = parseInt(i,10) -this.props.optionNumber + 3
  ////console.log('new setting',i,settings.initialSlide,children[i].attr)
        }

        //if (children[i].show)
      var active_view = false
      if (this.props.hasOwnProperty('active_view')) {
        active_view = children[i].active_view
      }
   //   else if (ALL_STONES.indexOf(this.props.attr) !== -1) {
   //     active_view ='1'
   //   }
      ////console.log ('AIOPTION',this.props.attr,active_view,this.props)
      let shapedict = {'R':'Round','O':'Oval','P':'Princess','C':'Cushion','E':'Emerald','PR':'Pear'}

      let prefix=undefined
      if (children[i].quality !== '') {
        
       /* else if (children[i].value.startsWith('TH2'))
        {
          prefix = shapedict[this.props.ds] + ' Halo'
        }*/
       // else {
          prefix = children[i].quality
        //}
      }
     
      let name_top=''

      if (children[i].hasOwnProperty('month')) {
          name_top = children[i].month.substring(0,3).toUpperCase()
      }

      let name = children[i].name
      if (this.props.image_base === 'ctw' && this.props.ds === 'O' && this.props.ce !== 'D')
        name = children[i].name2
      if (children[i].prefix !== '')
          prefix = children[i].prefix
      if (children[i].value.startsWith('T1'))
          {
            prefix = shapedict[this.props.ds] + ' Center'
          }
     // console.log('show',children[i],prefix)
      let show =true
      if (children[i].hasOwnProperty('show') && children[i].show === false)
        show = false
      //console.log('show',children[i].value,children[i].show,show)
      if (show)  
       rows.push(<AiOptionButtonWrap 
        name={name} 
        nameTop={name_top} 
        namePrefix={prefix} 
        attr={this.props.attr} 
        css={this.props.css} 
        value={children[i].value} 
        show={show} 
        active_view ={active_view}
        key={this.props.attr +'-'+i.toString()} 
        style={{ float:'left',height:optionSize}}
        image={image_url2} //{image_url}
        image2={image_url2}
        options_all={this.props.options_all}
            

        optionSize={optionSize} />);
    }
    let hr_not_last =[]
    if (this.props.last === 'false') {
      hr_not_last.push(<div className='ai-textinput-hr' key={'hr-' + this.props.attr }/>)
    ////console.log('hc not last',this.props.last)
    }
    const footer = (
      <div className='ai-best-close'>
          <Button label="Close" onClick={(e) => this.setState({ visibleDialog: false })} />

      </div>
  );
  
    if ((this.props.isposterminal === 'true' && this.props.name.toUpperCase() === 'DIAMOND') || (this.props.isposterminal !== 'true' && this.props.name.toUpperCase() === 'CENTER STONE')) {
      //<div className={imagebestclass} key='modalbutton'>
        let bcolor ='ai-bcolor3-' + window.currentSite
     /* modalbutton.push(
      <Button key='bestsellers' className={imagebestclass} tabIndex="2" aria-label="Top 10 Bestsellers"  label="Select from Bestsellers" onClick={(e) => {if (document. readyState === 'complete') this.setState({ visibleDialog: true });this.props.onOpenBS();}}></Button>)
     modalbutton.push(<Dialog key='dialog' header="Select Bestseller" visible={this.state.visibleDialog} resizable={true} draggable={false} maximized={true} className={'ai-best-dialog ' + bcolor + (bsoverride == true ? ' override' : '')} style={newstyle} onHide={(e) => this.setState({ visibleDialog: false })}>
    {bestsell}{footer}</Dialog>)*/
  }
    var note = ''
    if (this.props.attr === 'f') {
    note =<div className='ai-input-box-bottom'>
    <div className='ai-textinput-hr'/>
    NOTE: Personalization options do not support the use of emoji or symbols. Our experts will ensure the proper size and placement of your message.
    </div>
    }
   // var sliderSize = (rows.length * this.props.optionSize) +'px';
    var maxSliderSize = (this.props.optionNumber * this.props.optionSize +3 ) +'px';
    //console.log('max',maxSliderSize,rows.length);
    let fcolor ='ai-color-' + window.currentSite +' ai-option-title'
    let bcolor= this.props.optionName.replace('name','titlediv')
    if (this.props.name.toUpperCase() === 'MATCHING BAND'){
    fcolor ='ai-bcolor2-' + window.currentSite +' ai-option-title'
    bcolor = bcolor + ' ai-bcolor2-' + window.currentSite
    }
   var namereal= this.props.name.toUpperCase()
   if (this.props.store === '3')
        namereal=namereal.replace('CENTER','CENTRE').replace('COLOR','COLOUR')
   if (rows.length<= this.props.optionNumber)
    return (
     <div  style={{width:maxSliderSize,marginLeft:'30px'}} className={'ai-option-box' + this.props.optionName}>
   
      <div  className={bcolor}><div className='ai-option-title-left'></div>
      <div className={fcolor+ this.props.optionName.replace('name','titleline') }>{namereal}</div></div>
      {rows}
      <div style={{clear:'both'}}/>
      {note}
      {hr_not_last}
    </div>
    );
   else     
    return (
      <div  style={{width:maxSliderSize,marginLeft:'30px'}} className={'ai-option-box' + this.props.optionName}>
      <div><div className='ai-option-title-left'></div><div className={fcolor}>{namereal}</div></div>
        <Slider {...settings} style={{width:maxSliderSize,height:optionSize}}>
        {rows}
        </Slider>
        {note}
        {hr_not_last}
      </div>
    
    );
  }
}
export default AiOption;