import React, { Component } from 'react';
import Slider from "react-slick";
import Lightbox from 'react-image-lightbox';
import 'react-image-lightbox/style.css'; 
import { FocusRing } from '@react-aria/focus';

var lbstyle = ''

class AiImageBig extends Component {
  constructor(props) {
    super(props);
    this.closeLightBox = this.closeLightBox.bind(this);
    this.slider = React.createRef();
    this.state = {
      photoIndex: this.props.active_view
    };

    if ((window.innerWidth - window.innerHeight) < 90) {
      lbstyle = 'lbportrait'
      if ((window.innerHeight - window.innerWidth) < 100)
        lbstyle += ' lbsquare'
    }
    else {
      lbstyle = 'lblandscape'
    }

    this.updateDimensions = this.updateDimensions.bind(this);
  }

   componentWillUnmount() {
       window.removeEventListener("resize", this.updateDimensions);
   }
   componentDidMount() {
       window.addEventListener("resize", this.updateDimensions);
   }

   updateDimensions() {
    let chkstyle = ''
    if ((window.innerWidth - window.innerHeight) < 90) {
      chkstyle = 'lbportrait'
      if ((window.innerHeight - window.innerWidth) < 100)
        chkstyle += ' lbsquare'
    }
    else {
      chkstyle = 'lblandscape'
    }
    if (chkstyle !== lbstyle) {
      this.forceUpdate();
      //this.closeLightBox();
    }
    lbstyle = chkstyle
   }

  componentDidUpdate(){
      this.slider.current.slickGoTo(this.props.active_view)
  }

  closeLightBox () {
    this.props.onCloseOpenBox()
  } 
   
  render() {
    ////console.log('render IMAGE view=',this.props.slides)
    //var base_url = this.props.view_image_base;
    var base_url = this.props.slides;
    
    let sizeClass=this.props.size
    const settingsPortrait = {
      dots: false,
      //dotsClass: "slick-dots slick-thumb",
      infinite: false,
      className: 'ai-img-wrap-' +sizeClass,
      speed: 500,
      arrows:true,
      fade:true,
      lazyLoad:true,
      slidesToShow: 1,
      centerMode: false,
      slidesToScroll: 1,
      afterChange: (current) => {
        ////console.log('ch view',current);
        this.props.onChange(current);}
      
    };
    const settingsLandscape = {
      //?v=${i+1}
      customPaging: function(i) {
          //let slide = this.props.slides[i]
          return (
          <a>
            <img className={'ai-img-thumbnail-'+sizeClass} src={`${base_url[i]}`}  alt='Image'/> 
          </a>
        );
      },
      dots: true,
      dotsClass: 'slick-dots slick-dots-'+sizeClass + ' slick-thumb',
      infinite: false,
     // initialSlide: this.props.active_view ,
      speed: 500,
      arrows:false,
      className: 'ai-img-wrap-'+sizeClass,
      fade:true,
      lazyLoad:true,
      slidesToShow: 1,
      centerMode: false,
      //centerPadding: "20px",
      slidesToScroll: 1,
      //afterChange: (current) => {////console.log('ch view',current);}
      afterChange: (current) => {this.props.onChange(current);}
      
    };
    let settings = {}   
    if (this.props.orientation === 'portrait') {
      settings = settingsPortrait
    }
    else {
      settings = settingsLandscape
    }

    if ((window.innerWidth - window.innerHeight) < 90) {
      lbstyle = 'lbportrait'
      if ((window.innerHeight - window.innerWidth) < 100)
        lbstyle += ' lbsquare'
    }
    else {
      lbstyle = 'lblandscape'
    }


   const { photoIndex } = this.state;
   var images=this.props.slides
   var imagesZoom=this.props.slidesZoom
   return (
      <div className={'ai-image-big-wrap' + (this.props.initial === 'yes' ? ' ai-init' : '')}  >
        <Slider ref={this.slider}   {...settings} >
        {this.props.slides.map(slide =>
              // <div className={'ai-img-'+sizeClass}  key={slide}  >
            
                <img className={'ai-src-img-'+sizeClass}  
                key={slide}
                src={slide} 
                /* onClick={this.props.onOpenOpenBox} */
                alt=''   />
              
              // </div>
          
            
          )}
      
      </Slider>   
      {( this.props.pushOpenBox ) && (
           <Lightbox
            mainSrc={imagesZoom[photoIndex]}
            nextSrc={imagesZoom[(photoIndex + 1) % images.length]}
            prevSrc={imagesZoom[(photoIndex + images.length - 1) % images.length]}
            onCloseRequest={this.closeLightBox}
            wrapperClassName={lbstyle}
            /* reactModalStyle={lightBoxStyle.modal} */
            onMovePrevRequest={() =>
              this.setState({
                photoIndex: (photoIndex + images.length - 1) % images.length,
              })
            }
            enableZoom ={false}
            onMoveNextRequest={() =>
              this.setState({
                photoIndex: (photoIndex + 1) % images.length,
              })
            }  
             onAfterOpen={() =>
               this.setState({
                 photoIndex: this.props.active_view,
               })
             }  
          />
          )
        
      }

        </div>
    );
  }
}
export default AiImageBig;