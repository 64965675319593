import React, {Component} from 'react';
//import ReactDOM from 'react-dom';
import PropTypes from 'prop-types'
import {Menubar} from 'primereact/components/menubar/Menubar';
import {PanelMenu} from 'primereact/components/panelmenu/PanelMenu';
import {Button} from 'primereact/components/button/Button';


export default class Navbar extends Component {
    static propTypes = {
        ...Menubar.propTypes,
        ...PanelMenu.propTypes,
        logoSrc: PropTypes.string,
        toogleWidth: PropTypes.number,
    };

    state = {showPanel: false};


    renderCustomContent() {
        if (this.props.children) {
            return (
                <div className="navbar-custom">
                    {this.props.children}
                </div>
            );
        }
        else {
            return null;
        }
    }

    render() {
        let toogleWidth = this.props.toogleWidth || 500;
        const customContent = this.renderCustomContent();
        let menu = (
            <div className="desktop">
                <Menubar {...this.props} ref={'desktopMenubar'}/>
                {customContent}
            </div>
        );
        if (window.innerWidth < toogleWidth) {
            menu = (
                <div className="mobile">
                    <div style={{display: 'flex'}}>
                        <Button icon="fa fa-bars" className="ui-button-secondary"
                                onClick={() => this.setState({showPanel: !this.state.showPanel})}/>
                        {customContent}
                    </div>
                    {this.state.showPanel ? <PanelMenu {...this.props} /> : null}
                </div>
            )
        }
        return (
            <div className={'navbar'}>
                {menu}
            </div>
        )
    }
}
