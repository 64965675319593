import { connect } from 'react-redux'
import App from './App'
import {state_update,state_force_update, state_load_json,state_history,state_load_zsku } from './actions'
import {
  get_default_sku
} from './reducers/utils';
const mapStateToProps = (state, ownProps) => ({
 // initAttr: state.hasOwnProperty('init')? state['init']: false,
 // initValue: state.hasOwnProperty('init')? state[state['init']]: false,
//  initValue: state[state['init']],
  loading: state['loading'],
  json: state['json'],
  //s: state['s'],
  sku: state['sku'] === undefined?get_default_sku():state['sku'] ,
  zalessku: state['zalessku'] === undefined?'':state['zalessku'],
  cfg: state['cfg'],
  hc: state['hc'],
  hs: state['hs'],
  hu: state['hu'],
  he: state['he'],
  dc: state['dc'],
  imageversion:state['imageversion'],
  push_message:state['message']
})

const mapDispatchToProps = (dispatch, ownProps) => ({
  onLoad: ( (attr,value) => dispatch(state_force_update({attr:attr,value:value}))),
  onImage: ( (value) => dispatch(state_update({attr:'active_view',value:value}))),
  onUpdate: ( (attr,value) => dispatch(state_update({attr:attr,value:value}))),
  onRemoveMessage: ( () => dispatch(state_update({attr:'message',value:''}))),
  onJson: ( (value) => dispatch(state_load_json(value))),
  onZsku: ( (value) => dispatch(state_load_zsku(value))),
  onHistory: ( (value) => dispatch(state_history({attr:'sku',value:value})))
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(App)