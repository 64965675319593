import { connect } from 'react-redux'
import { state_update } from '../actions'
import AiOptionButton from './AiOptionButton'
import ReactGA from 'react-ga4';

const mapStateToProps = (state, ownProps) => ({
  active: ownProps.value === state[ownProps.attr] ? true: false,
  //select: ownProps.value === state[ownProps.attr] ? 'ai-text-select ai-select':'ai-text-unselect',
  //show: state['hide_'+ownProps.attr+'-' + ownProps.value] !== true ? 'ai-show':'ai-hide',
  select: ownProps.value === state[ownProps.attr] ? 'ai-text-select ai-select ai-select-'+state.currentSite :'ai-text-unselect',
  selectText: ownProps.value === state[ownProps.attr] ? 'ai-color-'+state.currentSite:'ai-text-unselect',
  
  showReview: state.showReview,
  cur_ga_event: state.ga_event,
  css:ownProps.css
})


 

const mapDispatchToProps = (dispatch, ownProps) => ({
      onClick: ( (state) => { 
        ////console.log('event selected',state)   
        /*if (state !== 'Selected')
        ReactGA.event({
        category: 'Selected',
        send_to:ReactGA._currentMeasurementI,
        action:ownProps.options_all.replace(ownProps.attr,'['+ownProps.attr +']') 
    });*/
    
  
    dispatch(state_update({attr:'initial',value:''}));
      dispatch(state_update({attr:ownProps.attr,value:ownProps.value}));
      if (ownProps.showReview !== true)
        dispatch(state_update({attr:'showReview',value:true}));
      if (ownProps.active_view !== false)
        dispatch(state_update({attr:'active_view',value:ownProps.active_view}))
      dispatch(state_update({attr:'ga_event',value:'Selected'}));
      }
    )
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(AiOptionButton)