
import {
    includeRule,
    excludeRule,hideOptionSet
  }
  from './rules_functions.js';

  
export function rules_bsetl(newState, state) {
    //rules with 4 conditions:
    var newrule = includeRule(newState, state, 'bsetl')
    var show = hideOptionSet(newState, state, [ ['band', ['0']]])
    if (show == true) show=hideOptionSet(newState, state, [ ['bset', ['0']]])
  if (show == true)
    newrule = excludeRule(newState, state, [
        ['band', ['C']],
        ['bsetl', ['3']]], newrule)
  if (show == true)
    newrule = excludeRule(newState, state, [
        ['band', ['S']],
        ['bsetc', ['SAD','BDID','RUD','EMD']],
        ['bsetl', ['3']]], newrule)
   // console.log('ALL Include rules bsetl 2', newrule)
   newrule['show']  = show
      return newrule
}