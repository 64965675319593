import { connect } from 'react-redux'
import { state_update } from '../actions'
import AiInputDia from './AiInputDia'

const mapStateToProps = (state, ownProps) => ({
  //childrenE: ownProps.product==='ring'?state['options_er'].options:state['options_eb'].options,
  optionE: state['options_uinfo'],
  loading:state.loading,
  product:ownProps.product,
  maxSliderSize :ownProps.maxSliderSize
                

})


 

const mapDispatchToProps = (dispatch, ownProps) => ({
  onClick: ( () => dispatch(state_update({attr:ownProps.attr,value:ownProps.value}))),
  onLoadingDone: (() =>{ 
    ////console.log('onLoadingDone')
  //  dispatch(state_update({attr:'showSticky',value:true}) )
   //// dispatch(state_update({attr:'loading-'+ownProps.product,value:'done'}) )
  }
  ),
 
 // onClick: () =>state_update({attr:ownProps.attr,value:ownProps.value})
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(AiInputDia)