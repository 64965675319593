import { connect } from 'react-redux'
//import { state_update } from '../actions'
import LeftPanel from './LeftPanel'
import { state_start_over,state_update } from '../actions'
import {decodeHtml} from '../reducers/utils'
import ReactGA from 'react-ga4';

const mapStateToProps = (state, ownProps) => ({
  title:state['initial']===''?decodeHtml(state['title']):'',
  cfg:state['cfg1'],
  initial: state['initial']

})
  
 

const mapDispatchToProps = (dispatch, ownProps) => ({
  onStartOver: ( () =>{
    /*ReactGA.event({
      category: 'StartOver',
      action:'StartOver' 
    });*/
    dispatch(state_start_over())}),
  onPushOpenBox: ( (e) =>{
    if(e && e.target){
      if(!e.target.getAttribute('class').includes('thumbnail') && e.target.localName != 'button'){
      /*ReactGA.event({
        category: 'Zoom',
        action:'Zoom' 
      });*/
      dispatch(state_update({attr:'pushOpenBox',value:true}))}
    }
    else {
      dispatch(state_update({attr:'pushOpenBox',value:true}))
    }
  })
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(LeftPanel)