
import {
    includeRule,
    excludeRule,
    hideOptionSet
  }
  from './rules_functions.js';
  
  
export function rules_pkb(newState, state) {
    var newrule = includeRule(newState, state, 'pkb')
    
    var show =  hideOptionSet(newState, state, [ ['sh', [ '4', '5',  '8', '10', '11','15','16']]])
    
  

    newrule['show']  = show
    return newrule
}