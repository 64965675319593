import ZalesHeader from "./zales"

class ZalesOutletHeader extends ZalesHeader {
    logoSrc = 'https://static.thepersonalizedbest.com/header/z-outlet.svg';
    simpleBanner = {
        href: "https://www.zalesoutlet.com/the-zales-difference",
        title: "free shipping every day",
        imgSrc: "https://www.zalesoutlet.com/images/h1a/h3a/9778850725918.jpg"
    };
}

export default ZalesOutletHeader
