import React, { Component } from 'react';
import { FocusRing } from '@react-aria/focus';

class AiOptionButton extends Component { 

    render() {
////console.log('renderOption',this.props.active,this.props.attr,this.props.value);
        let emptyPrefix = this.props.namePrefix === undefined ? ' ai-empty-prefix':'';
////console.log('emptyPrefix',emptyPrefix,this.props.namePrefix);
        let css_name_top = ''
        let css_name_prefix = ''
        let css_name_text = ''
        let css_name_image = ''
        let details=''
        if (this.props.attr==='diunique')
            details=<div className='buttonUnique' 
            onClick={()=>this.props.onClick(this.props.cur_ga_event)}
             tabIndex="0"  aria-label={this.props.name}>details</div>
       // console.log('button',this.props)    
        let  css_name = this.props.css
        ////console.log('rowOptionButton',css_name,this.props)
        if (css_name !== undefined) {
            css_name_top = ' ai-button-text-top-' + css_name
            css_name_prefix = ' ai-button-text-prefix-' + css_name
            css_name_text = ' ai-button-text-' + css_name
            css_name_image = ' ai-button-image-' + css_name
        }
    return (
        
        <FocusRing focusRingClass="focus-ring">
            <button className='button' 
             onClick={()=>this.props.onClick(this.props.cur_ga_event)}
              tabIndex="0"  aria-label={this.props.name}
              style={{width:this.props.optionSize,float:'left'}} 
              >
        <div style={{width:this.props.optionSize,height:this.props.optionSize,float:'left'}} 
        /* onClick={()=>this.props.onClick(this.props.cur_ga_event)} */
        disabled={this.props.active}
        className={'ai-option-button ai-show '+this.props.select +' ai-option-button-'+this.props.optionSize  + ' ai-option-box-height-'+css_name}
        >
        <div className={'ai-border-box ai-option-border-box-height-'+css_name}> 
        <i className={'ai-check-icon fa '+this.props.select}/>
        <div className={'ai-button-text-top '+this.props.selectText + css_name_top}>{this.props.nameTop}</div>
        <img className={css_name_image +' ai-button-image' } src={this.props.image} alt={this.props.name}
         srcSet={`${this.props.image} 1x,${this.props.image2} 2x`}
        />
        <div className={'ai-button-text-prefix '+this.props.selectText  + css_name_prefix} >{this.props.namePrefix}</div>
        <div className={'ai-button-text '+this.props.selectText + emptyPrefix  + css_name_text}>{this.props.name}</div>
        {details}
        <div className={'corner '+this.props.select}/>
        </div>
        </div>
        
        </button>
        </FocusRing>
        );  
    }
}

  
export default AiOptionButton;
