
import {
    includeRule,
    excludeRule,
    hideOptionSet
  }
  from './rules_functions.js';
  
  
export function rules_uinfo(newState, state) {
    var newrule = includeRule(newState, state, 'uinfo')
    

    var show =  hideOptionSet(newState, state, [ ['ud', ['D']]])

   

    newrule['show']  = show
    return newrule
}