import React, { Component } from 'react';
import AiOptionSetWrap from './AiOptionSetWrap';
import AiToolbarWrap from './AiToolbarWrap';
import Sticky  from "react-sticky-el";
//import {Button} from 'primereact/components/button/Button';

class RightPanel extends Component {
    render() {

      return (<div className='ai-right-panel'>
      <Sticky stickyClassName='ai-sticky' topOffset={0} id='stick' 
      positionRecheckInterval={100} disabled={this.props.showSticky}>
      <AiToolbarWrap className='ai-toolbar-panel'/>
      </Sticky>
      
        <div className='ai-all-options'>


     <AiOptionSetWrap optionNumber={this.props.optionNumber} optionSize={this.props.optionSize}
        orientation={this.props.orientation}/>
      
      
    
  </div>
      </div>
        );
    }
}

export default RightPanel;