
import {
    includeRule,
    hideOptionSet
  }
  from './rules_functions.js';
  
  
export function rules_hmetc(newState, state) {
    var newrule = includeRule(newState, state, 'hmetc')
    
    var show =  hideOptionSet(newState, state, [ ['head', [ 'P1']]])
    if (show == true)  
       show =  hideOptionSet(newState, state, [ ['rmet', [ 'PT']]])
    
      
    newrule['show']  = show
    return newrule
}