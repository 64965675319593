import {
  includeRule,
  excludeRule,
  hideOptionSet
}
from './rules_functions.js';

  
  
export function rules_dctw(newState, state) {
  //rules with 4 conditions:
  var newrule = includeRule(newState, state, 'dctw')
  var show  = hideOptionSet(newState, state, [ ['ud', ['U']]])
  
    newrule = excludeRule(newState, state, [
        ['ce', ['D','UD']],
        ['dctw', ['C1', 'C2', 'C3', 'C4']]], newrule)
   
    
    newrule = excludeRule(newState, state, [
        ['ce', ['C']],
        ['dctw', ['0.25', '0.33', '0.5', '0.75', '1', '1.25', '1.5', '2', '2.5', '3', 'C1']]], newrule)
     newrule = excludeRule(newState, state, [
          ['ce', ['LD']],
          ['dctw', ['0.25', '0.33', '0.5', '0.75', 'C1','C2', 'C3', 'C4']]], newrule)

  newrule['show']  = show
    return newrule
}


