import React, { Component } from 'react';
import {InputText} from 'primereact/components/inputtext/InputText';
import { ENGRAVING_TIMEOUT,ENGRAVING_URL } from '../constants';
import {filter} from '../reducers/utils'


class AiInputText extends Component {
  constructor(props){
    super(props);
  //  this.inputText = React.createRef();
    let len= 0
    let text =''
     
    if (this.props.value !== undefined)  {
//     text =filter(this.props.value,this.props.filter,this.props.maxLength)
      text =this.props.value
      len = text.length
    }
    ////console.log('TEXT CONSTR',this.props.attr,this.props.value) //,this.state.text)
 
    this.state = { len: len, text:text };
    this.onChange = this.onChange.bind(this);  
    this.props.onLoadingDone()
   
}
  componentDidUpdate(){
    
    var newText =filter(this.props.value,this.props.filter,this.props.maxLength)
    ////console.log('newText',newText,this.props,this.state.text)

    if (newText !== this.state.text   && this.props.loading ==='new'  ) {
      ////console.log('newText changed')
      this.setState({text : newText })
    this.setState({len : newText.length})
    this.props.onChange(newText)

    this.props.onLoadingDone()
    }
    ////console.log('TEXT DIDupdate',this.props.attr,this.props.value,this.state.text,this.props.loading)
   
   
    //else 
    if (this.props.value !== this.state.text /*&& this.props.loading !=='new'*/ )
    {
      setTimeout(() => { 
     ////console.log('On Change Timeout',this.state.text)
        this.props.onChange(this.state.text)      }, ENGRAVING_TIMEOUT)
    }
      
}

onChange(e) { 
   const newText = filter(e.target.value,this.props.filter); 
   ////console.log('onchange(e)',this.state.text,newText,this.props.loading,this.props.filter) 
   if (newText !== this.state.text ) {
      this.setState({text : newText});  
      this.setState({len : newText.length});
  }
   ////console.log('onchange(e) end',this.state) 
  
}  

  render() {
    ////console.log('RENDER TextInput',this.props.attr,this.props.value,'loading-',this.props.loading)
    
   //onChange={(e) => {this.setState ({len:this.getLength(e.target.value)});this.props.onChange(e.target.value)}}/>
    //this.setState ({len:this.props.value.length,text:filter(this.props.value)})
    ////console.log('maxSliderSize3',this.props.maxSliderSize,this.props.product_type)
    let metal='s'
    if (this.props.product_type==='ring'){
    if (this.props.smetal==='Y'){
      metal='g'
    }
    else if (this.props.smetal==='R'){
      metal='r'
    }
  }
  else{
    if (this.props.bmetal==='Y'){
      metal='g'
    }
    else if (this.props.bmetal==='R'){
      metal='r'
    }
  }
    let e=ENGRAVING_URL+'?t='+encodeURIComponent(this.state.text)+'&f=0&w='+this.props.maxSliderSize +'&m='+metal
  ////console.log('renderInput',e)
   return (
     <div  className={'ai-inputtext-box '+this.props.show} >
    <img className='ai-engr-show' style={{width:this.props.maxSliderSize+'px'}} alt=""
     src={e} />
    <div className={'ai-inputtext-box '+this.props.show}   >
    <span className="ai-inputtext-wrap"  style={{width:this.props.maxSliderSize4+'px'}}>
    <InputText  style={{width:(this.props.maxSliderSize4)+'px'}}
    key={this.props.key}
    aria-required="true"
    aria-label="Engraving" 
    // ref="inputText"
    className='ai-inputtext'
    type="text" 
   // defaultValue={this.props.value}
    onFocus={()=>this.props.onFocus(this.props.cur_ga_event)}
    maxLength={this.props.maxLength} 
    
    value={this.state.text}
    onChange={this.onChange}
    onBlur={this.props.onBlur}  
    />
            
    </span>
    
    </div>
    <div className="ai-inputtext-count">{this.state.len} of {this.props.maxLength}</div>
    </div>
);
  }
}
//export default connect()(AiInput);
export default AiInputText;