import React, { Component } from 'react';
import AiImageBigWrap from './AiImageBigWrap';
import Sticky  from "react-sticky-el";
import {OPTION_URL} from '../constants';
import { FocusRing } from '@react-aria/focus';

//import {Button} from 'primereact/components/button/Button';

class LeftPanel extends Component { 
    render() {
      return (<div className={'ai-left-panel' + (this.props.initial === 'yes' ? ' ai-init' : '')} >

       
    
    <Sticky className={'ai-sticky-left' + (this.props.initial === 'yes' ? ' ai-init' : '')} topOffset={0}>  
    <div className={"ai-cfg"}>SKU #<div>{this.props.cfg}</div></div>
    {/* <div className='ai-cfg'>Stock #{this.props.cfg}</div> */}
    <div className='ai-title'>{this.props.title}&nbsp;</div>
    <FocusRing focusRingClass="focus-ring">
       <button className='button2 ai-startover'
        onClick={this.props.onStartOver}>
        <img className="ai-startover-button" 
        src={OPTION_URL + "small/button/so3.png"} alt="Start Over"
        srcSet={OPTION_URL + "small/button/so3.png 1x, " +OPTION_URL + "large/button/so3.png 2x"}
        />
        </button>
        </FocusRing>
        <FocusRing focusRingClass="focus-ring">
       <button className='button2 ai-zoom'
        onClick={this.props.onPushOpenBox}>
        <img className="ai-zoom-button" 
        src={OPTION_URL + "small/button/z3.png"} 
        srcSet={OPTION_URL + "small/button/z3.png 1x, " +OPTION_URL + "large/button/z3.png 2x"}
        alt="Zoom"/>
        </button>
        </FocusRing>
        <FocusRing focusRingClass="focus-ring">
       <button className='button2 ai-mainzoom' onClick={this.props.onPushOpenBox}>
    <AiImageBigWrap size={this.props.size}  orientation={this.props.orientation} /> 
    </button>
        </FocusRing>
    </Sticky> 
    
      </div>
        );
    }
}



export default LeftPanel;