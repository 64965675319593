import {
  DEFAULT_K_SKU,
  DEFAULT_Z_SKU,DEFAULT_ZO_SKU,DEFAULT_PPL_SKU,
  DEFAULT_J_SKU
} from '../constants';
export  function getValueFromDictArray (ArrayIn,KeyIn,ValueIn,KeyOut) {
    ////console.log('In dict',ArrayIn)
    for (let i in ArrayIn) {
      if (ArrayIn[i][KeyIn] === ValueIn){
        if (ArrayIn[i].hasOwnProperty(KeyOut))
          return ArrayIn[i][KeyOut]
        else
          break  
      }    
    }
    return undefined
  }
  export function get_default_sku(){
    var ini_sku=  DEFAULT_K_SKU
    if (window.currentSite === 'jared')  
      ini_sku= DEFAULT_J_SKU
    else if   (window.currentSite === 'zales')  
      ini_sku= DEFAULT_Z_SKU
    else if   (window.currentSite === 'zalesoutlet')  
      ini_sku= DEFAULT_ZO_SKU
    else if   (window.currentSite === 'peoplesjewellers')  
      ini_sku= DEFAULT_PPL_SKU
    
    return ini_sku
    }
  export function get_sku(state){
   ////console.log('get_sku',state['sku'])
   return  state['sku']
  }
  export function filter(In1,filterIn,maxLengthIn) {
   // console.log('FILTER',In1,filterIn,maxLengthIn)
    let res=''  
    if (In1 === undefined)
      return ''
    switch (filterIn) {
      case undefined:
      case 'engraving':
        //res = In1.replace(/[^a-z0-9_.\-@ !$%{}[\]:;"'<>,\\?/()+=]/ig,'')
        res = In1.replace(/[^a-z0-9_.\-@#*^& !|`~\\$%{}[\]:;"'<>,\\?/()+=]/ig,'')
        break
      case 'nameplate':
        res = In1.replace(/[^a-z]/ig,'')
        res= res.substr(0,1).toUpperCase()+res.substring(1).toLowerCase()
        break
      case 'monogram':
        ////console.log('monogram')
        res = In1.replace(/[^a-z]/ig,'')
        break
      case 'digits':
          ////console.log('monogram')
          res = In1.replace(/[^0-9.]/ig,'')
          break
        default:
        return In1
        
    }  
    return res.substring(0,maxLengthIn)
  }  
  
 export   function decodeHtml(html) {
    var txt = document.createElement("textarea");
    txt.innerHTML = html;
    return txt.value;
  }
  
  
  export  function  diamond_minmax(i)
  {
    if (i==='1/20'   ) return '0.04 - 0.06'
    if (i==='1/15'   ) return '0.065 - 0.08'
    if (i==='1/10'   ) return '0.085 - 0.11'
    if (i==='1/8'    ) return '0.115 - 0.14'
    if (i==='1/6'    ) return '0.145 - 0.17 '
    if (i==='1/5'    ) return '0.18 - 0.22'  
    if (i==='1/4'    ) return '0.23 - 0.28'  
    if (i==='1/3'    ) return '0.29 - 0.36'  
    if (i==='3/8'    ) return '0.37 - 0.44' 
    if (i==='1/2'    ) return '0.45 - 0.57'  
    if (i==='5/8'    ) return '0.58 - 0.68'  
    if (i==='3/4'    ) return '0.69 - 0.82'  
    if (i==='7/8'    ) return '0.83 - 0.94'  
    if (i==='1'      ) return '0.95 - 1.11' 
    if (i==='1 1/8'  ) return '1.115 - 1.14'
    if (i==='1 1/6'  ) return '1.145 - 1.17' 
    if (i==='1 1/5'  ) return '1.18 - 1.22' 
    if (i==='1 1/4'  ) return '1.23 - 1.28'  
    if (i==='1 1/3'  ) return '1.29 - 1.36' 
    if (i==='1 3/8'  ) return '1.37 - 1.44'
    if (i==='1 1/2'  ) return '1.45 - 1.57'  
    if (i==='1 5/8'  ) return '1.58 - 1.68'  
    if (i==='1 3/4'  ) return '1.69 - 1.82'  
    if (i==='1 7/8'  ) return '1.83 - 1.94'  
    if (i==='2'      ) return '1.95 - 2.11' 
    if (i==='2 1/8'  ) return '2.115 - 2.14'
    if (i==='2 1/6'  ) return '2.145 - 2.17' 
    if (i==='2 1/5'  ) return '2.18 - 2.22'  
    if (i==='2 1/4'  ) return '2.23 - 2.28'  
    if (i==='2 1/3'  ) return '2.29 - 2.36'  
    if (i==='2 3/8'  ) return '2.37 - 2.44'  
    if (i==='2 1/2'  ) return '2.45 - 2.57'  
    if (i==='2 5/8'  ) return '2.58 - 2.68'  
    if (i==='2 3/4'  ) return '2.69 - 2.82'  
    if (i==='2 7/8'  ) return '2.83 - 2.94'  
    if (i==='3'      ) return '2.95 - 3.11' 
    if (i==='3 1/8'  ) return '3.115 - 3.14'
    if (i==='3 1/6'  ) return '3.145 - 3.17' 
    if (i==='3 1/5'  ) return '3.18 - 3.22'  
    if (i==='3 1/4'  ) return '3.23 - 3.28'  
    if (i==='3 1/3'  ) return '3.29 - 3.36'  
    if (i==='3 3/8'  ) return '3.37 - 3.44'  
    if (i==='3 1/2'  ) return '3.45 - 3.57'  
    if (i==='3 5/8'  ) return '3.58 - 3.68'  
    if (i==='3 3/4'  ) return '3.69 - 3.82'  
    if (i==='3 7/8'  ) return '3.83 - 3.94'  
    if (i==='4'      ) return '3.95 - 4.11' 
    if (i==='4 1/8'  ) return '4.115 - 4.14'
    if (i==='4 1/6'  ) return '4.145 - 4.17' 
    if (i==='4 1/5'  ) return '4.18 - 4.22'  
    if (i==='4 1/4'  ) return '4.23 - 4.28'
    if (i==='4 1/3'  ) return '4.29 - 4.36'
    if (i==='4 3/8'  ) return '4.37 - 4.44'
    if (i==='4 1/2'  ) return '4.45 - 4.57'
    if (i==='4 5/8'  ) return '4.58 - 4.68'
    if (i==='4 3/4'  ) return '4.69 - 4.82'
    if (i==='4 7/8'  ) return '4.83 - 4.94'
    if (i==='5'      ) return '4.95 - 5.11'
    if (i==='5 1/8'  ) return '5.115 - 5.14'
    if (i==='5 1/6'  ) return '5.145 - 5.17'
    if (i==='5 1/5'  ) return '5.18 - 5.22'
    if (i==='5 1/4'  ) return '5.23 - 5.28'
    if (i==='5 1/3'  ) return '5.29 - 5.36'
    if (i==='5 3/8'  ) return '5.37 - 5.44'
    if (i==='5 1/2'  ) return '5.45 - 5.57'
    if (i==='5 5/8'  ) return '5.58 - 5.68'
    if (i==='5 3/4'  ) return '5.69 - 5.82'
    if (i==='5 7/8'  ) return '5.83 - 5.94'
    if (i==='6'      ) return '5.95  - 6.11'
    if (i==='6 1/8'  ) return '6.115 - 6.14'
    if (i==='6 1/6'  ) return '6.145 - 6.17'
    if (i==='6 1/5'  ) return '6.18 - 6.22'
    if (i==='6 1/4'  ) return '6.23 - 6.28'
    if (i==='6 1/3'  ) return '6.29 - 6.36'
    if (i==='6 3/8'  ) return '6.37 - 6.44'
    if (i==='6 1/2'  ) return '6.45 - 6.57'
    if (i==='6 5/8'  ) return '6.58 - 6.68'
    if (i==='6 3/4'  ) return '6.69 - 6.82'
    if (i==='6 7/8'  ) return '6.83 - 6.94'
    if (i==='7'      ) return '6.95 - 7.11'
    if (i==='7 1/8'  ) return '7.115 - 7.14'
    if (i==='7 1/6'  ) return '7.145 - 7.17'
    if (i==='7 1/5'  ) return '7.18 - 7.22'
    if (i==='7 1/4'  ) return '7.22 - 7.28'
    if (i==='7 1/3'  ) return '7.29 - 7.36'
    if (i==='7 3/8'  ) return '7.37 - 7.44'
    if (i==='7 1/2'  ) return '7.45 - 7.57'
    if (i==='7 5/8'  ) return '7.58 - 7.68'
    if (i==='7 3/4'  ) return '7.69 - 7.82'
    if (i==='7 7/8'  ) return '7.83 - 7.94'
    if (i==='8'      ) return '7.95 - 8.11'
    if (i==='8 1/8'  ) return '8.115 - 8.14'
    if (i==='8 1/6'  ) return '8.145 - 8.17'
    if (i==='8 1/5'  ) return '8.18 - 8.22'
    if (i==='8 1/4'  ) return '8.23 - 8.28'
    if (i==='8 1/3'  ) return '8.29 - 8.36'
    if (i==='8 3/8'  ) return '8.37 - 8.44'
    if (i==='8 1/2'  ) return '8.45 - 8.57'
    if (i==='8 5/8'  ) return '8.58 - 8.68'
    if (i==='8 3/4'  ) return '8.69 - 8.82'
    if (i==='8 7/8'  ) return '8.83 - 8.94'
    if (i==='9'      ) return '8.95 - 9.11'
    if (i==='9 1/8'  ) return '8.115 - 9.14'
    if (i==='9 1/6'  ) return '9.145 - 9.17'
    if (i==='9 1/5'  ) return '9.18 - 9.22'
    if (i==='9 1/4'  ) return '9.23 - 9.28'
    if (i==='9 1/3'  ) return '9.29 - 9.36'
    if (i==='9 3/8'  ) return '9.37 - 9.44'
    if (i==='9 1/2'  ) return '9.45 - 9.57'
    if (i==='9 5/8'  ) return '9.58 - 9.68'
    if (i==='9 3/4'  ) return '9.69 - 9.82'
    if (i==='9 7/8'  ) return '9.83 - 9.94'
    if (i==='10'     ) return '9.95 - 10.11'
    if (i==='10 1/8' ) return '10.115 - 10.14'
    if (i==='10 1/6' ) return '10.145 - 10.17'
    if (i==='10 1/5' ) return '10.18 - 10.22'
    if (i==='10 1/4' ) return '10.23 - 10.28'
    if (i==='10 1/3' ) return '10.29 - 10.36'
    if (i==='10 3/8' ) return '10.37 - 10.44'
    if (i==='10 1/2' ) return '10.45 - 10.57'
    if (i==='10 5/8' ) return '10.58 - 10.68'
    if (i==='10 3/4' ) return '10.69 - 10.82'
    if (i==='10 7/8' ) return '10.83 - 10.94'
    if (i==='11'     ) return '10.95 - 11.11'
    if (i==='11 1/8' ) return '11.115 - 11.14'
    if (i==='11 1/6' ) return '11.145 - 11.17'
    if (i==='11 1/5' ) return '11.18 - 11.22'
    if (i==='11 1/4' ) return '11.23 - 11.28'
    if (i==='11 1/3' ) return '11.29 - 11.36'
    if (i==='11 3/8' ) return '11.37 - 11.44'
    if (i==='11 1/2' ) return '11.45 - 11.57'
    if (i==='11 5/8' ) return '11.58 - 11.68'
    if (i==='11 3/4' ) return '11.69 - 11.82'
    if (i==='11 7/8' ) return '11.83 - 11.94'
    if (i==='12'	 ) return '11.95 - 12.11' 
   return '0 - 0'
  }
  
  
  