
import {
    includeRule,
    excludeRule
  }
  from './rules_functions.js';

  
export function rules_rset(newState, state) {
    //rules with 4 conditions:
    var newrule = includeRule(newState, state, 'rset')
        ////console.log('ALL Include rules', newrule)
    newrule = excludeRule(newState, state, [
            ['sh', ['2','4','11','16']],
            ['rset', ['PV']]], newrule)
     
    newrule = excludeRule(newState, state, [
                ['sh', ['5']],
                ['rset', ['CHR','PR']]], newrule)
   // newrule = excludeRule(newState, state, [
   //                 ['sh', ['16']],
   ///                 ['rset', ['CHR']]], newrule)
        newrule = excludeRule(newState, state, [
                    ['sh', ['11']],
                    ['rset', ['0']]], newrule)
      
  newrule['show']  = true
      return newrule
}