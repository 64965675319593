
import {
    includeRule,
    excludeRule,hideOptionSet
  }
  from './rules_functions.js';

  
export function rules_bset(newState, state) {
    //rules with 4 conditions:
    var newrule = includeRule(newState, state, 'bset')
        ////console.log('ALL Include rules', newrule)
    var show  = hideOptionSet(newState, state, [ ['band', ['0']]])
    if (show === true) 
    newrule = excludeRule(newState, state, [
            ['sh', ['2','4','11','16']],
            ['bset', ['PV']]], newrule)
   // newrule = excludeRule(newState, state, [
   //             ['sh', ['16']],
   //             ['rset', ['CHR']]], newrule)        
    if (show === true) 
            newrule = excludeRule(newState, state, [
                ['sh', ['5']],
                ['bset', ['CHR','PR']]], newrule)
  
    
  newrule['show']  = show
      return newrule
}