import React, { Component } from 'react';
import RightPanelWrap from './RightPanelWrap';
import LeftPanelWrap from './LeftPanelWrap';
import MainBodyPortraitWrap from './MainBodyPortraitWrap';
import AiHeader from './AiHeader';
import AiFooter from './AiFooter';


class AiLayout extends Component {
    render() {
      let body =''
      if (this.props.orientation === 'error')  
        body = <div  className='ai-main-body'>
        </div>
      else if (this.props.orientation === 'portrait')  
      body = <div  className='ai-main-body'>
      <MainBodyPortraitWrap   className='ai-main-body-panel' size={this.props.image} optionNumber={this.props.optionNumber}
          optionSize={this.props.optionSize} orientation={this.props.orientation}/> 
      </div>
     else
        body = <div  className='ai-panels'>
        <LeftPanelWrap    className='ai-leftpanel' size={this.props.image}  orientation={this.props.orientation}/> 
        <RightPanelWrap  
            optionNumber={this.props.optionNumber}
            optionSize={this.props.optionSize}
            orientation={this.props.orientation}/> 
        </div>
         
      return (<div  className='ai-body'>
        <AiHeader size={this.props.header} messages={this.props.messages}/>
        {body}
        
        <AiFooter size={this.props.footer}/> 
        </div>
        );
    }
}
export default AiLayout;