
import {
    includeRule,
    hideOptionSet
  }
  from './rules_functions.js';
  
  
export function rules_smetc(newState, state) {
    var newrule = includeRule(newState, state, 'smetc')
    
    var show =  hideOptionSet(newState, state, [ ['rmet', [ 'PT']]])
    
      
    newrule['show']  = show
    return newrule
}