import {Component} from "react";
import Navbar from "../navbar";
import React from "react";
//import {Tooltip} from 'react-tippy';


class ZalesHeader extends Component {

    toogleWidth = 1310;
    isMobileLayout = false;
    logoSrc = 'https://static.thepersonalizedbest.com/header/9920790036510.png';
    logoTitle = 'Zales. The Diamond Store. Since 1924';
    creditCartServicesUrl = "https://creditservices.zales.com/";
    simpleBanner = {
        href: "/the-zales-difference?icid=HMP:SHIP_HEADER:NOMIN",
        title: "Free Shipping No Minimum, to your door or to your store >",
        imgSrc: "https://static.thepersonalizedbest.com/header/10070753116190.png"
    };
    cartIconClass = 'icon-inline icon-shopping-bag-empty-mt';



    state = {
        showSearchPanel: false,
        cartContent: (
            null
        ),
        cartTotal: 0,
        banner: this.simpleBanner
    };


       


    render() {
        let showZalesSecondaryBarStyle = {};
        this.isMobileLayout = window.innerWidth < this.toogleWidth;
        var bann=<div/>
        if (this.simpleBanner.href!=='')
            bann=               <div className="simple-banner-component1">
            <a href={window.baseSiteUrl + this.state.banner.href}><img
                className="img-responsive"
                title={this.state.banner.title}
                alt={this.state.banner.title}
                src={this.state.banner.imgSrc}/>
            </a>
        </div>

        if (this.isMobileLayout)
            showZalesSecondaryBarStyle = {display: 'none'};
        return (
            <div className="zales">
                <div className="inner-container" style={showZalesSecondaryBarStyle}>
                    <div className="row">
                        <div className="ui-md-7 contact-navigation">
                            
                        </div>
                        <div className="ui-md-5 my-account-navigation hidden-sm hidden-xs ">
                            <ul className="nav-links">
                                <li className="top-nav-link liOffcanvas"><a
                                    href={window.baseSiteUrl + "/favorites"}
                                    title="Favorites"> Favorites</a><span
                                    className="signet-mark">&nbsp;<i className="fa fa-heart"></i></span>

                                </li>
                                <li className="top-nav-link"><a
                                    href={window.baseSiteUrl + "/guest/guest-order-status"}
                                    title="Order Status"> Order
                                    Status</a></li>
                                <li className="top-nav-link"><a href={window.baseSiteUrl + "/store-finder"}
                                                                title="Find A Location">
                                    Find A Location</a> <span className="signet-mark">&nbsp;<i
                                    className="fa fa-map-marker"></i></span></li>
                            </ul>
                        </div>
                    </div>
                </div>
                <div className="secondary-navigation" style={showZalesSecondaryBarStyle}>
                    <div className="inner-container">
                        <div className="row">
                            <div className="ui-md-4 nav-promotion">
                                <div className="header-col-inner">
                                {bann}
                                </div>
                            </div>
                            <div className="ui-md-4 company-logo">
                                <div className="header-col-inner">
                                    
                                    <div className="simple-banner-component1">
                                        <a href={window.baseSiteUrl}><img className="img-responsive zologo"
                                                                          title={this.logoTitle}
                                                                          alt={this.logoTitle}
                                                                          src={this.logoSrc}/></a>
                                    </div>
                                </div>
                            </div>
                            <div className="ui-md-4 search-navigation">
                                <div className="header-col-inner">
                                    <div className="row">
                                        <div className="search-container ui-md-10" role="search">
                                            <form name="search_form_SearchBox" method="get"
                                                  action={window.baseSiteUrl + "/search/"}>
                                                <div className="input-group">
                                                    <input placeholder="What can we help you find?" autoComplete="off"
                                                           name="text" maxLength="100"
                                                           className="typeahead form-control" type="text"/>
                                                    <button className="btn btn-link" type="submit">
                                                        <span className="fa fa-search "></span>
                                                    </button>
                                                </div>
                                            </form>
                                        </div>
                                        <div className="ui-md-2" style={{display: 'inherit'}}>
                                            <div className="shopping-cart">
                                                <div className="mini-cart-body">
                                                    {/*<Tooltip*/}
                                                        {/*position="bottom"*/}
                                                        {/*animation="scale"*/}
                                                        {/*arrow="true"*/}
                                                        {/*arrowSize="big"*/}
                                                        {/*theme="light"*/}
                                                        {/*interactive*/}
                                                        {/*offset={-100}*/}
                                                        {/*html={this.state.cartContent}*/}
                                                    {/*>*/}
                                                        <div onClick={()=>{window.location.href=`${window.baseSiteUrl}/cart`}} className={this.cartIconClass}></div>
                                                        {/*<span id="mini-cart-comp-total"*/}
                                                              {/*className="shopping-cart-total">{this.state.cartTotal}</span>*/}
                                                    {/*</Tooltip>*/}
                                                </div>
                                            </div>
                                            <div id="bridal_menu_desktop">
                                                <div data-reactroot="" className="" style={{display: 'inline'}}
                                                     data-tooltipped="" aria-describedby="tippy-tooltip-2"
                                                     data-original-title="undefined">
                                                    <div className="bridal-menu"></div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <Navbar model={this.props.model} toogleWidth={this.toogleWidth}>
                    {this.isMobileLayout &&
                    <div style={{display: 'flex'}}>
                        <div className="ui-grid-col-1">
                            <a href={window.currentSiteUrl + "/store-finder"} className="mobile-map-marker"
                               title="Find Stores">
                                <span className="signet-mark"><i className="fa fa-map-marker"></i></span>
                            </a>
                        </div>
                        <div className="ui-grid-col-8" style={{textAlign: 'center'}}>
                            <a href={window.baseSiteUrl}><img className="img-responsive" width="150px"
                                                              title={this.logoTitle}
                                                              alt={this.logoTitle}
                                                              src={this.logoSrc}></img></a>
                        </div>
                        <div className="ui-grid-col-3">
                            <div className="mobile-right" id="miniCartSlot">
                                <button className="mobile-search-icon" type="button"
                                        onClick={() => this.setState({showSearchPanel: !this.state.showSearchPanel})}>
                                    <span><i className="fa fa-search"></i></span>
                                </button>
                                <div className="mini-cart-body">
                                    <a href={window.currentSiteUrl + '/cart/'}>
                                        <div className={this.cartIconClass}></div>
                                        <span id="mini-cart-comp-total"
                                              className="shopping-cart-total"></span>
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                    }
                </Navbar>
                {this.isMobileLayout && this.state.showSearchPanel &&
                <div className="search-form">
                    <form name="search_form_SearchBox" method="get"
                          action={window.currentSiteUrl + "/search/"}>
                        <div className="input-group">
                            <input placeholder="What can we help you find?" autoComplete="off"
                                   name="text" maxLength="100"
                                   className="typeahead form-control" type="text"/>
                            <button className="btn btn-link" type="submit">
                                <span className="fa fa-search "></span>
                            </button>
                        </div>
                    </form>
                </div>
                }
            </div>
        )
    }
}

export default ZalesHeader;