
import {
    includeRule,
    excludeRule,
    hideOptionSet
  }
  from './rules_functions.js';
  
  
export function rules_dq(newState, state) {
    var newrule = includeRule(newState, state, 'dq')
    
    var show =  hideOptionSet(newState, state, [ ['ce', ['C','LD']]])
    //console.log('dq rules show1',hide)
    if (show == true)  {
      show  = hideOptionSet(newState, state, [ ['ud', ['U']]])
    }
    //console.log('dq rules show2',hide)
    
    
   /* if (show == true)  {
      newrule = excludeRule(newState, state, [
        ['ds', ['E']],
        ['dq', ['I']]], newrule)
      newrule = excludeRule(newState, state, [
          ['dctw', ["1.5","2"]],
          ['ds', ["C","E","O","PR"]],
          ['dq', ['VS']]], newrule)
        }
      newrule = excludeRule(newState, state, [
          ['ce', ['LD']],
          ['dq', ['SI','I']]], newrule)
*/
    newrule['show']  = show
    return newrule
}