export const config_kay23 = {
    "title": "Bridal Customizer",
    "max_view": 3,
    //"view_image_base": "//test.thepersonalizedbest.com/bridal_image5/",
    "view_image_base": "//bridalimages.thepersonalizedbest.com/bridal_image5/",
    "options_all": [
        "ud", "ce", "cec",
        "ds", "dctw","dq", "uinfo",
        "head", "headc", "sh", "pkb", 
        "rset", "rsetc", "rsetl", "ren",
        "rmet", "hmetc", "smetc", 
        "rs",
        "er",
        "band",
        "bset", "bsetc", "bsetl", "ben", 
        "bmet", "bmetc",
        "eb1",
    ],
    "options_ce": {
        "image_base": "ce7",
        "name": "Center Stone",
        "show": true,
        "option_name": "ce",
        "type": "control",
        "css": "ce",
        "image_postfix": "",
        "options": [{
                "image": "d.jpg",
                "quality": "",
                "name": "Diamond",
                "value": "D"
            }, {
                "image": "c.jpg",
                "quality": "",
                "name": "Gemstone",
                "value": "C"
            },

        ]
    },
    "options_cec": {
        "image_base": "cec6",
        "name": "Center Gemstone",
        "show": false,
        "option_name": "cec",
        "type": "control",
        "css": "cec",
        "image_postfix": "ds",
        "options": [{
                "image": "sa.jpg",
                "quality": "",
                "name": "Sapphire",
                "value": "SA"
            }, {
                "image": "em.jpg",
                "quality": "",
                "name": "Emerald",
                "value": "EM"
            }, {
                "image": "ta.jpg",
                "quality": "",
                "name": "Tanzanite",
                "value": "TA"
            }, {
                "image": "ru.jpg",
                "quality": "",
                "name": "Ruby",
                "value": "RU"
            /*}, {
                "image": "ys.jpg",
                "quality": "",
                "name": "Yellow Sapphire",
                "value": "YS"
            }, {
                "image": "ps.jpg",
                "quality": "",
                "name": "Pink Sapphire",
                "value": "PS"*/
            }
        ]
    },
    "options_ud": {
        "image_base": "button",
        "name": "Diamond",
        "show": false,
        "css": "ud",
        "option_name": "ud",
        "type": "control",
        "image_postfix": "",
        "options": [{
                "image": "diamondlist.jpg",
                "name": "Need a Diamond",
                "value": "D"

            }, {
                "image": "diamonduser.jpg",
                "name": "Have a Diamond",
                "value": "U"
            }
        ]
    },
    "options_ds": {
        "image_base": "shape",
        "name": "Center Stone Shape",
        "show": true,
        "option_name": "ds",
        "css": "ds",
        "type": "control",
        "image_postfix": "",
        "options": [{
                "image": "dsr.jpg",
                "name": "Round",
                "value": "R"
            }, {
                "image": "dso.jpg",
                "name": "Oval",
                "value": "O"
            }, {
                "image": "dsc.jpg",
                "name": "Cushion",
                "value": "C"
            }, {
                "image": "dsp.jpg",
                "name": "Princess",
                "value": "P"
            }, {
                "image": "dspr.jpg",
                "name": "Pear",
                "value": "PR"
            }, {
                "image": "dse.jpg",
                "name": "Emerald",
                "value": "E"
            }
            , {
                "image": "dsm.jpg",
                "name": "Marquise",
                "value": "M"
            }
        ]
    },
    "options_dctw": {
        "image_base": "ctw",
        "name": "Center Stone Size",
        "show": true,
        "option_name": "dctw",
        "type": "control",
        "css": "ctw",
        "image_postfix": "",
        "options": [{
                "image": "0.25.jpg",
                "name": "1/4 CT.",
                "value": "0.25"
            }, {
                "image": "0.33.jpg",
                "name": "1/3 CT.",
                "value": "0.33"
            }, {
                "image": "0.5.jpg",
                "name": "1/2 CT.",
                "value": "0.5"
            }, {
                "image": "0.75.jpg",
                "name": "3/4 CT.",
                "value": "0.75"
            }, {
                "image": "1.jpg",
                "name": "1 CT.",
                "value": "1"
            }, /*{
                "image": "1.25.jpg",
                "name": "1 1/4 CT.",
                "value": "1.25"
            },*/ {
                "image": "1.5.jpg",
                "name": "1 1/2 CT.",
                "value": "1.5"
            }, {
                "image": "2.jpg",
                "name": "2 CT.",
                "value": "2"
            }, {
                "image": "2.jpg",
                "name": "2 1/2 CT.",
                "value": "2.5"
            }, {
                "image": "2.5.jpg",
                "name": "3 CT.",
                "value": "3"
            }, {
                "image": "0.84.jpg",
                "name": "6 MM.",
                "name2": "7x5 MM.",
                "value": "C2"
            }, {
                "image": "1.3.jpg",
                "name": "7 MM.",
                "name2": "8x6 MM.",
                "value": "C3"

            }
            /*, {
                "image": "2.jpg",
                "name": "8 MM.",
                "name2": "9x7 MM.",
                "value": "C4"
            }*/
        ]
    },
    "options_dq": {
        "image_base": "qual6",
        "name": "Upgrade Center Stone Diamond Quality",
        "show": true,
        "option_name": "dq",
        "type": "control",
        "css": "dq",
        "image_postfix": "",
        "options": [{
                "image": "good.jpg",
                "quality": "",
                "name": "",
                "value": "I"
            }, {
                "image": "better.jpg",
                "quality": "",
                "name": "",
                "value": "SI"
            }, {
                "image": "best.jpg",
                "quality": "",
                "name": "",
                "value": "VS"
            }
        ]
    },
    "options_head": {
        "image_base": "heads6",
        "name": "Ring Head",
        "show": true,
        "option_name": "head",
        "type": "control",
        "css": "heads",
        "image_postfix": "ds",
        "options": [{
                "image": "H1-NA.jpg",
                "quality": "",
                "name": "Single Halo",
                "value": "H1"
            }, {
                "image": "S1-NA.jpg",
                "quality": "",
                "name": "4 Prongs",
                "value": "S1"
            }, {
                "image": "SS2-NA.jpg",
                "quality": "4 Prongs",
                "name": "Pave",
                "value": "SS2"
            }, 
             {
                "image": "C1-NA.jpg",
                "quality": "",
                "name": "Crown",
                "value": "C1"
            },
           
             {
                "image": "P1-NA.jpg",
                "quality": "4 Prong",
                "name": "Peg Head",
                "value": "P1"
            },
            {
                "image": "T1-TSR.jpg",
                "quality": "3 Stone Round",
                "name": "& Round Sides",
                "value": "T1TSR"
            },
            {
                "image": "T1-TSP.jpg",
                "quality": "3 Stone Princess",
                "name": "& Princess Sides",
                "value": "T1TSP"
            },
      /*      {
                "image": "T1-TSO.jpg",
                "quality": "3 Stone Oval",
                "name": "& Oval Sides",
                "value": "T1TSO"
            },  */
            
            {
                "image": "T1-TSTR.jpg",
                "quality": "3 Stone Trillion",
                "name": "& Trillion Sides",
                "value": "T1TSTR"
            }, 
  /*           {
                "image": "TH2-TSTR.jpg",
                "quality": "3 Stone Halo",
                "name": "& Trillion Sides",
                "value": "TH2TSTR"
            }, */
            {
                "image": "H2-NA.jpg",
                "quality": "",
                "name": "Double Halo",
                "value": "H2"
            },
            
  /*          {
                "image": "SS1-NA.jpg",
                "quality": "4 Prongs",
                "name": "Round",
                "value": "SS1"
            }, */
           
            {
                "image": "T1-TSPR.jpg",
                "quality": "3 Stone Pear",
                "name": "& Pear Sides",
                "value": "T1TSPR"
            },
/*            {
                "image": "TH2-TSR.jpg",
                "quality": "3 Stone Halo",
                "name": "& Round Sides",
                "value": "TH2TSR"
            } */
            
            {
                "image": "B1-NA.jpg",
                "quality": "",
                "name": "Bezel",
                "value": "B1"
            }, 
            {
                    "image": "H3-NA.jpg",
                    "quality": "",
                    "name": "Flower Halo",
                    "value": "H3"
            }
        ]
    },
    "options_rs": {
        "image_base": "rs",
        "name": "Ring Size",
        "show": true,
        "option_name": "size",
        "type": "select",
        "css": "rs",
        "image_postfix": "",
        "options": [{
                "image": "",
                "name": "",
                "show": false,
                "value": "0"
            }, {
                "image": "4.jpg",
                "name": "",
                "value": "4"
            }, {
                "image": "45.jpg",
                "name": "",
                "value": "4.5"
            }, {
                "image": "5.jpg",
                "name": "",
                "value": "5"
            }, {
                "image": "55.jpg",
                "name": "",
                "value": "5.5"
            }, {
                "image": "6.jpg",
                "name": "",
                "value": "6"
            }, {
                "image": "65.jpg",
                "name": "",
                "value": "6.5"
            }, {
                "image": "7.jpg",
                "name": "",
                "value": "7"
            }, {
                "image": "75.jpg",
                "name": "",
                "value": "7.5"
            }, {
                "image": "8.jpg",
                "name": "",
                "value": "8"
            }, {
                "image": "85.jpg",
                "name": "",
                "value": "8.5"
            }, {
                "image": "9.jpg",
                "name": "",
                "value": "9"
            }
        ],
        "attr": "rs"
    },
    "options_rmet": {
        "image_base": "metals6",
        "name": "Metal",
        "show": true,
        "option_name": "rmet",
        "type": "control",
        "css": "line1",
        "image_postfix": "",
        "options": [{
                "image": "10.jpg",
                "name": "Gold",
                "value": "10"
            }, {
                "image": "14.jpg",
                "name": "Gold",
                "value": "14"
            }, {
                "image": "18.jpg",
                "name": "Gold",
                "value": "18"
            }, {
                "image": "PT.jpg",
                "name": "Platinum",
                "value": "PT"

            }
        ]
    },
    "options_bmet": {
        "image_base": "metals6",
        "name": "Band Metal",
        "show": false,
        "option_name": "bmet",
        "css": "line1",
        "type": "control",
        "image_postfix": "",
        "options": [{
                "image": "10.jpg",
                "name": "Gold",
                "value": "10"
            }, {
                "image": "14.jpg",
                "name": "Gold",
                "value": "14"
            }, {
                "image": "18.jpg",
                "name": "Gold",
                "value": "18"
            }, {
                "image": "PT.jpg",
                "name": "Platinum",
                "value": "PT"
            }
        ]
    },
    "options_hmetc": {
        "image_base": "metalcolors6",
        "name": "Ring Head Color",
        "show": true,
        "css": "line1",
        "option_name": "hmetc",
        "type": "select",
        "image_postfix": "",
        "options": [{
                "image": "W.jpg",
                "name": "White Gold",
                "value": "W"
            }, {
                "image": "Y.jpg",
                "name": "Yellow Gold",
                "value": "Y"
            }, {
                "image": "R.jpg",
                "name": "Rose Gold",
                "value": "R"
            }
        ]
    },
    "options_smetc": {
        "image_base": "metalcolors6",
        "name": "Mounting Color",
        "show": true,
        "css": "line1",
        "option_name": "smetc",
        "type": "select",
        "image_postfix": "",
        "options": [{
                "image": "W.jpg",
                "name": "White Gold",
                "value": "W"
            }, {
                "image": "Y.jpg",
                "name": "Yellow Gold",
                "value": "Y"
            }, {
                "image": "R.jpg",
                "name": "Rose Gold",
                "value": "R"
            }
        ]
    },
    "options_bmetc": {
        "image_base": "metalcolors6",
        "name": "Band Metal Color",
        "show": false,
        "option_name": "bmetc",
        "css": "line1",

        "type": "select",
        "image_postfix": "",
        "options": [{
                "image": "W.jpg",
                "name": "White Gold",
                "value": "W"
            }, {
                "image": "Y.jpg",
                "name": "Yellow Gold",
                "value": "Y"
            }, {
                "image": "R.jpg",
                "name": "Rose Gold",
                "value": "R"
            }
        ]
    },
    "options_headc": {
        "image_base": "stones7",
        "name": "Head Stones",
        "show": false,
        "option_name": "headc",
        "css": "singlestone",
        "type": "select",
        "image_postfix": "",
        "options": [{
                "image": "d.jpg",
                "name": "Diamond",
                "value": "D"
            }, {
                "image": "sa.jpg",
                "name": "Sapphire",
                "value": "SA"
            }, {
                "image": "bd.jpg",
                "name": "Black Diamond",
                "value": "BDI"
            /*}, {
                "image": "ru.jpg",
                "name": "Ruby",
                "value": "RU" 
            }, {
                "image": "em.jpg",
                "name": "Emerald",
                "value": "EM"
            }, {
                "image": "ta.jpg",
                "name": "Tanzanite",
                "value": "TA"*/
            }
        ]
    },
    "options_rsetc": {
        "image_base": "shankgems",
        "name": "Side Stones",
        "show": false,
        "attr": "rsetc",
        "css": "shankgems",
        "option_name": "rsetc",
        "type": "control",
        "image_postfix": "",
        "options": [{
            "image": "d.jpg",
            "name": "Diamond",
            "value": "D" 
        }, {
            "image": "sad.jpg",
            "name": "Alt. Sapphire",
            "value": "SAD"
        }, {
            "image": "bd.jpg",
            "name": "Black Diamond",
            "value": "BDI"
        }, {
            "image": "bdid.jpg",
            "name": "Alt. Black Diamond",
            "value": "BDID"
        }, {
            "image": "emd.jpg",
            "name": "Alt. Emerald",
            "value": "EMD"
        }, {
            "image": "rud.jpg",
            "name": "Alt. Ruby",
            "value": "RUD"
       /* }, {
            "image": "sa.jpg",
            "name": "Sapphire",
            "value": "SA"
        }, {
            "image": "em.jpg",
            "name": "Emerald",
            "value": "EM"
        }, {
            "image": "ru.jpg",
            "name": "Ruby",
            "value": "RU"*/
        }
        ]
    },
    "options_bsetc": {
        "image_base": "shankgems",
        "name": "Band Side Stones",
        "show": false,
        "css": "shankgems",
        "option_name": "bsetc",
        "type": "control",
        "image_postfix": "",
        "options": [{
                "image": "d.jpg",
                "name": "Diamond",
                "value": "D" 
            }, {
                "image": "sad.jpg",
                "name": "Alt. Sapphire",
                "value": "SAD"
            }, {
                "image": "bd.jpg",
                "name": "Black Diamond",
                "value": "BDI"
            }, {
                "image": "bdid.jpg",
                "name": "Alt. Black Diamond",
                "value": "BDID"
            }, {
                "image": "emd.jpg",
                "name": "Alt. Emerald",
                "value": "EMD"
            }, {
                "image": "rud.jpg",
                "name": "Alt. Ruby",
                "value": "RUD"
            /*}, {
                "image": "sa.jpg",
                "name": "Sapphire",
                "value": "SA"
            }, {
                "image": "em.jpg",
                "name": "Emerald",
                "value": "EM"
            }, {
                "image": "ru.jpg",
                "name": "Ruby",
                "value": "RU"*/
            }
        ]
    },
    "options_sh": {
        "image_base": "shank",
        "name": "Mounting",
        "show": true,
        "option_name": "sh",
        "css": "shank",
        "type": "control",
        "image_postfix": "",
        "options": [{"image": "1.jpg","name": "Single","value": "1"
            }, {   "image": "4.jpg", "name": "Double Twist", "value": "4"
            }, {"image": "5.jpg", "name": "Knife Edge", "value": "5"
            }, {"image": "10.jpg","name": "Tapered", "value": "10"
            }, {"image": "2.jpg","name": "Double", "value": "2"
            }, {"image": "11.jpg","name": "Contemporary", "value": "11"
            }, {"image": "8.jpg", "name": "Square Edge", "value": "8"
        }, {"image": "15.jpg", "name": "Hidden Halo", "value": "15"
    }
    , {"image": "16.jpg", "name": "Split", "value": "16"
}

        ]
    },
    "options_pkb": {
        "image_base": "pkb6",
        "name": "Peekaboo Shape",
        "show": true,
        "option_name": "pkb",
        "css": "pkb",
        "type": "control",
        "image_postfix": "",
        "options": [{
                "image": "none.jpg",
                "name": "None",
                "value": "0"
            }, {
                "image": "round.jpg",
                "name": "Round",
                "value": "R"
            }, {
                "image": "princess.jpg",
                "name": "Princess",
                "value": "P"
            },

        ]
    },
    "options_pkbc": {
        "image_base": "stones7",
        "name": "Peekaboo Stone",
        "show": false,
        "option_name": "pkbc",
        "type": "select",
        "css": "singlestone",
        "image_postfix": "",
        "options": [{
                "image": "d.jpg",
                "name": "Diamond",
                "value": "D"
            }, {
                "image": "sa.jpg",
                "name": "Sapphire",
                "value": "SA"
            }, {
                "image": "bd.jpg",
                "name": "Black Diamond",
                "value": "BDI"

            }, {
                "image": "ru.jpg",
                "name": "Ruby",
                "value": "RU"

            }, {
                "image": "em.jpg",
                "name": "Emerald",
                "value": "EM"

            }
        ]
    },
    "options_rset": {
        "image_base": "rset",
        "name": "Side Setting",
        "show": true,
        "option_name": "rset",
        "css": "set",
        "type": "control",
        "image_postfix": "",
        "options": [{
                "image": "0.jpg",
                "name": "None",
                "value": "0"
            }, {
                "image": "UP.jpg",
                "name": "U-Pave",
                "value": "UP"
            }, {
                "image": "CHR.jpg",
                "name": "Channel",
                "value": "CHR"
            }, {
                "image": "PR.jpg",
                "name": "Prong",
                "value": "PR"
            }, {
                "image": "BD.jpg",
                "name": "Bead",
                "value": "BD"

            }, {
                "image": "PV.jpg",
                "name": "Pave",
                "value": "PV"
            }

        ]
    },
    "options_bset": {
        "image_base": "bset",
        "name": "Band Side Setting",
        "show": false,
        "css": "set",
        "option_name": "bset",
        "type": "control",
        "image_postfix": "",
        "options": [{
                "image": "0.jpg",
                "name": "None",
                "value": "0"
            }, {
                "image": "UP.jpg",
                "name": "U-Pave",
                "value": "UP"
            }, {
                "image": "CHR.jpg",
                "name": "Channel",
                "value": "CHR"
            }, {
                "image": "PR.jpg",
                "name": "Prong",
                "value": "PR"
            }, {
                "image": "BD.jpg",
                "name": "Bead",
                "value": "BD"
            }, {
                "image": "PV.jpg",
                "name": "Pave",
                "value": "PV"
            },
        ]
    },
    "options_bsetl": {
        "image_base": "length6",
        "name": "Band Side Stones Length",
        "show": false,
        "option_name": "bsetl",
        "type": "select",
        "css": "len",

        "image_postfix": "",
        "options": [{
                "image": "l0.5.jpg",
                "name": "Half",
                "value": "1"
            }, {
                "image": "l0.75.jpg",
                "name": "Three-Quarters",
                "value": "2"
            }, {
                "image": "l1.0.jpg",
                "name": "Full",
                "value": "3",
                "show": false
            },

        ]
    },
    "options_rsetl": {
        "image_base": "length6",
        "name": "Side Stones Length",
        "show": false,
        "option_name": "rsetl",
        "type": "select",
        "css": "len",

        "image_postfix": "",
        "options": [{
                "image": "l0.5.jpg",
                "name": "Half",
                "value": "1"
            }, {
                "image": "l0.75.jpg",
                "name": "Three-Quarters",
                "value": "2"
            },

        ]
    },
    "options_band": {
        "image_base": "band",
        "name": "Matching Band",
        "show": true,
        "option_name": "band",
        "type": "control",
        "css": "band",
        "image_postfix": "",
        "options": [{
                "image": "none.jpg",
                "name": "None",
                "value": "0"
            }, {
                "image": "contour.jpg",
                "name": "Contour",
                "value": "C"
            }, {
                "image": "straight.jpg",
                "name": "Straight",
                "value": "S"
            },

        ]
    },
    "options_ren": {
        "image_base": "carving6",
        "name": "Carving",
        "show": true,
        "css": "carv",
        "option_name": "ren",
        "type": "control",
        "image_postfix": "",
        "options": [{
                "image": "c0.jpg",
                "name": "Plain",
                "value": "0"
            }, {
                "image": "c1.jpg",
                "name": "Leaf",
                "value": "1"
            }, {
                "image": "c2.jpg",
                "name": "Scroll",
                "value": "2"
            /*}, {
                "image": "c3.jpg",
                "name": "Feather",
                "value": "3"*/
            },
        ]
    },
    "options_renl": {
        "image_base": "length6",
        "name": "Carving Length",
        "show": false,
        "css": "len",
        "option_name": "renl",
        "type": "select",
        "image_postfix": "",
        "options": [{
                "image": "l0.5.jpg",
                "name": "Half",
                "value": "1"
            }, {
                "image": "l0.75.jpg",
                "name": "Three-Quarters",
                "value": "2"
            }
        ]
    },
    "options_ben": {
        "image_base": "carving6",
        "name": "Band Carving",
        "show": false,
        "css": "carv",
        "option_name": "ben",
        "type": "control",
        "image_postfix": "",
        "options": [{
                "image": "c0.jpg",
                "name": "Plain",
                "value": "0"
            }, {
                "image": "c1.jpg",
                "name": "Leaf",
                "value": "1"
            }, {
                "image": "c2.jpg",
                "name": "Scroll",
                "value": "2"
            /*}, {
                "image": "c3.jpg",
                "name": "Feather",
                "value": "3"*/
            }
        ]
    },
    "options_benl": {
        "image_base": "length6",
        "name": "Band Carving Length",
        "show": false,
        "option_name": "benl",
        "type": "select",
        "css": "len",

        "image_postfix": "",
        "options": [{
                "image": "l0.5.jpg",
                "name": "Half",
                "value": "1"
            }, {
                "image": "l0.75.jpg",
                "name": "Three-Quarters",
                "value": "2"
            }, {
                "image": "l1.0.jpg",
                "name": "Full",
                "value": "3"
            },
        ]
    },
    "options_er": {
        "show": true,
        "type": "input",
        "options": [{
                "attr": "er",
                "show": true,
                "init_text": "Message",
                "switch_view": 2,
                "maxLength": "25",
                "name": ""
            }
        ],
        "name": "Ring Engraving",
        "attr": "er",
    },
  
    "options_eb1": {
        "show": false,
        "type": "input",
        "options": [{
                "attr": "eb1",
                "show": true,
                "init_text": "Message",
                "switch_view": 2,
                "maxLength": "25",
                "name": ""
            }
        ],
        "name": "Band Engraving",
        "attr": "eb1",
    },
 
    "options_uinfo": {
        "show": false,
        "type": "inputDia",
        "options": [{
                "attr": "dct",
                "show": true,
                "init_text": "ctw",
                "type": 'input',
                "maxLength": "10",
                "name": "Carat weight (0.23 - 2.58 cw)"
            }, {
                "attr": "dc",
                "show": true,
                "init_text": "Message",
                "type": 'combobox',
                "maxLength": "10",
                "name": "Color",
                "menu": ["J", "I", "H", "G", "F", "E", "D"]
            }, {
                "attr": "dcl",
                "show": true,
                "init_text": "Message",
                "type": 'combobox',
                "maxLength": "10",
                "name": "Clarity",
                "menu": ["I1", "SI2", "SI1", "VS2", "VS1", "VVS2", "VVS1", "IF", "FL"]
            }, {
                "attr": "dl",
                "show": true,
                "init_text": "Message",
                "type": 'input',
                "maxLength": "10",
                "name": "Length (3 - 10 mm)"
            }, {
                "attr": "dw",
                "show": true,
                "init_text": "Message",
                "type": 'input',
                "maxLength": "10",
                "name": "Width (3 - 10 mm)"
            }, {
                "attr": "dd",
                "show": true,
                "init_text": "Message",
                "maxLength": "10",
                "type": 'input',
                "name": "Depth (3 - 10 mm)"
            }
        ],
        "name": "DIAMOND INFORMATION  (All fields are required)"
    },
}