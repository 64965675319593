import React, { Component } from 'react';
import AiInputTextWrapDia from './AiInputTextWrapDia';

  

//const AiInput = ({ dispatch }) => {
class AiInputDia extends Component {
  
  render() {
   ////console.log('RENDER Input',this.props.loading)
   var rows = [];
   ////console.log('Start constr InputSet',this.props.product,this.props) 
   if (this.props.optionE !== undefined) {
   var children = this.props.optionE.options
   ////console.log('constr InputSetDia',this.props,children) 
   for (let i in children) {
    // if (children[i].show)
      let view = '1'
      rows.push(<AiInputTextWrapDia 
      id={children[i].attr}  
      name={children[i].name} 
      attr={children[i].attr} 
      value={children[i].value} 
      type={children[i].type} 
      menu={children[i].menu} 
      show={children[i].show} 
      product={this.props.product} 
      filter={this.props.optionE.filter} 
      switchView={view} 
      key={i.toString()} 
      maxSliderSize={this.props.maxSliderSize}
      options_all={this.props.options_all}
      maxLength={children[i].maxLength} />);

    }
   }
   ////console.log('maxSliderSize2',this.props.maxSliderSize)
  
   //this.props.onLoadingDone()
  //<div className='ai-option-title-left'>TYPE YOUR</div>
   return (
   
    <div  className='ai-input-box-dia'>
      <div className='ai-option-title'>{this.props.name}</div>
      
    {rows}
    <div className="ai-textinput-hr"></div>
    </div>
    
);
  }
}
export default AiInputDia;
