import { connect } from 'react-redux'
import AiReview from './AiReview'
import ReactGA from 'react-ga4';
const mapStateToProps = (state, ownProps) => ({
  properties: state.refreshReview !==undefined?state.refreshReview.refresh_parent:[],
  description:state.refreshReview !==undefined?state.refreshReview.refresh_parent.description:'',
  version:state.version,
  site:state.store,
  showReview: 'ai-show',
 
})


const mapDispatchToProps = (dispatch, ownProps) => ({

  onOpenReview: ( () => {
    ////console.log('review event')
   /* ReactGA.event({
    category: 'Review',
    action:'Review'})*/
  }
    )
  /////onClick: ( () => dispatch(state_update({attr:'review',value:false})))
 // onClick: () =>state_update({attr:ownProps.attr,value:ownProps.value})
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(AiReview)