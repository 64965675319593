import {
  includeRule,
  excludeRule,
  hideOptionSet
}
from './rules_functions.js';

  
  
export function rules_rsetc(newState, state) {
  //rules with 4 conditions:
  var newrule = includeRule(newState, state, 'rsetc')
  var show  = hideOptionSet(newState, state, [ ['rset', ['0']]])
  newrule['show']  = show
    return newrule
}


