import {Component} from "react";
import Navbar from "../navbar";
import React from "react";


class KayHeader extends Component {
    toogleWidth = 1310;
    isMobileLayout = false;

    constructor(props) {
        super(props);
        this.state = {searchString: ''};
        this.onSearchInputChange = this.onSearchInputChange.bind(this);
    }

    onSearchInputChange(event) {
        this.setState({searchString: event.target.value});
    }

    onSearchClick(event) {
        event.preventDefault();
        document.getElementById('searchForm').submit()
    }

    render() {
        let styleHidden = {}
        this.isMobileLayout = window.innerWidth < this.toogleWidth;
        if (this.isMobileLayout)
            styleHidden = {display: 'none'};
        return (
            <div className="kay">
                <div className="top-page-util-bar__wrapper">
                    <div className="top-page-util-bar row">
                        <div className="ui-lg-12">
                            <div className="top-page-util-bar__right" style={styleHidden}>
                                <ul>
                                <li className="util-dropdown" id="instantCreditId"><a
                                        href={window.baseSiteUrl + "/favorites"}>
                                        Favorites&nbsp;<i className="fa fa-heart" ></i>
                                    </a></li>
                                
                                    <li><a
                                        href={window.baseSiteUrl + "/store-finder"}>
                                        Find a Store&nbsp;<i className="fa fa-map-marker"></i></a>
                                    </li>
                                    <li className="util-dropdown" id="instantCreditId"><a
                                        href={window.baseSiteUrl + "/credit-landing"}>
                                        Apply Now&nbsp;<i className="fa fa-credit-card"></i>
                                    </a></li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="site-header-wrapper">
                    <header className="site-header row">
             
                        <div className="site-header__mobile-btns site-header__mobile-btns--left" style={styleHidden}>
                        <div className="simple-banner-component"> 
                        <a href="https://www.kay.com/free-2nd-day-shipping-promotion">
                        <img className="img-responsive" title="Header Image" alt="" src="https://static.thepersonalizedbest.com/header/kay-free.png"/>
                        </a>
                        </div>
                        </div>
                        <div className="site-header__mobile-btns site-header__mobile-btns--right" style={styleHidden}>
                         <div>
                        <form id="searchForm"
                                  action={window.baseSiteUrl + "/search/" }>
                                <div id="searchBar" className="desktop-header-search" style={{display: 'block'}}>
                                    <div className="gb-kay-sayt gb-ui">
                                        <div className="gb-kay-search-box gb-ui"><input
                                            className="gb-kay-search-box__input" ref="searchBox" type="text" name="text"
                                            onChange={this.onSearchInputChange}
                                            placeholder="What can we help you find?" autoFocus=""/>
                                        </div>
                                        <div className="gb-submit">
                                            <gb-link className="gb-ui"><a onClick={this.onSearchClick} >
                                                <svg width="1.25rem" height="1.25rem" viewBox="0 0 99 99" version="1.1">
                                                    <path
                                                        d="M63.821165,55.998888 C64.6941426,58.4890372 62.2707218,62.3158956 58.7374251,61.9792003 C48.9296475,53.5085301 44.0257587,49.2731951 44.0257587,49.2731951 C39.4132643,52.807016 33.6440764,54.9101401 27.4036429,54.9101401 C12.2952249,54.9101401 5.68434189e-14,42.5918414 5.68434189e-14,27.4550701 C5.68434189e-14,12.3182988 12.2809447,-2.84217094e-14 27.3893627,-2.84217094e-14 C42.4977807,-2.84217094e-14 54.7930055,12.3182988 54.7930055,27.4550701 C54.7930055,33.5069172 52.8223423,39.1009414 49.5093433,43.63625 C49.5093433,43.63625 54.2799505,47.7571293 63.821165,55.998888 Z M27.4036429,47.12715 C38.2280104,47.12715 47.0388742,38.2997512 47.0531544,27.4550701 C47.0531544,16.596082 38.2422905,7.76868319 27.4036429,7.76868319 C16.5649952,7.76868319 7.75413135,16.596082 7.75413135,27.4407631 C7.75413135,38.2997512 16.5649952,47.12715 27.4036429,47.12715 Z"></path>
                                                </svg>
                                            </a></gb-link>
                                        </div>
                                    </div>
                                </div>
                            </form>
                            </div>   
                           
                            <a href={window.baseSiteUrl + "/cart"}
                               className="site-header__button--bag" id="shoppingCartBar">
                               
                            </a>
                        </div>
                        <Navbar model={this.props.model} toogleWidth={this.toogleWidth}>
                            {this.isMobileLayout &&
                            <div style={{display: 'flex'}}>
                                <div style={{textAlign: 'center', width: '100%'}}>
                                    <a className="logo" href={window.baseSiteUrl} title="Kay Jewelers">&nbsp;</a>
                                </div>
                                <div style={{minWidth: '50px'}}>
                                    <div className="site-header__mobile-btns site-header__mobile-btns--right">
                                       
                                        <a href={window.baseSiteUrl + "/cart"}
                                           className="site-header__button--bag" id="shoppingCartBar">
                                        </a>
                                    </div>
                                </div>
                            </div>
                            }
                        </Navbar>
                        
                        <a className="site-header__logo" href={window.baseSiteUrl} title="Kay Jewelers"
                           style={styleHidden}>&nbsp;</a>
                         
                    </header>
                </div>
            </div>
        )
    }
}

export default KayHeader;