import ZalesFooter from "./zales"
import React from "react";

class PeoplesJewelersFooter extends ZalesFooter {
    siteDomain = 'PEOPLESJEWELLERS.COM'
    getShopOutlet(){
        return ( <br/>)
    }
    getSocialIcons() {
        return (
            <ul style={this.styleHidden} className="social no-padding">
                <li>
                    <a href="https://www.facebook.com/Peoples">
                        <img
                            alt="View on Facebook"
                            src="/img/social_icons/facebook.png"
                            style={{display: 'inline'}}/>
                    </a>
                </li>
                <li>
                    <a href="https://twitter.com/peoplesdiamonds">
                        <img
                            alt="View on Twitter" src="/img/social_icons/twitter.png"
                            style={{display: 'inline'}}/>
                    </a>
                </li>
                <li>
                    <a href="https://www.instagram.com/peoplesjewellers/">
                        <img
                            alt="View on Instagram" src="/img/social_icons/insta.png"
                            style={{display: 'inline'}}/>
                    </a>
                </li>
                
                <li>
                    <a href="https://www.pinterest.com/peoplesjeweller/">
                        <img
                            alt="View on Pinterest"
                            src="/img/social_icons/pinterest.png"
                            style={{display: 'inline'}}/>
                    </a>
                </li>
                
            </ul>
        )
    }
    getSocialIconsDiv() {
        return (
            <div style={this.styleHidden} className="social" >
                
                <span style={{height: '41px', marginLeft:'8px', zIndex: '1000'}}>
                    <a href="https://www.facebook.com/Peoples">
                        <img
                            alt="View on Facebook"
                            src="/img/social_icons/facebook.png"
                            style={{display: 'inline'}}/>
                    </a>
                </span>
                <span style={{height: '41px', marginLeft:'8px', zIndex: '1000' }}>
                    <a href="https://twitter.com/peoplesdiamonds">
                        <img
                            alt="View on Twitter" src="/img/social_icons/twitter.png"
                            style={{display: 'inline'}}/>
                    </a>
                </span>
              
                <span style={{height: '41px', marginLeft:'8px', zIndex: '1000', }}>
                    <a href="https://www.pinterest.com/peoplesjeweller/">
                        <img
                            alt="View on Pinterest"
                            src="/img/social_icons/pinterest.png"
                            style={{display: 'inline'}}/>
                    </a>
                </span>
                <span style={{height: '41px', marginLeft:'8px',zIndex: '1000'}}>
                    <a href="https://www.instagram.com/peoplesjewellers/">
                        <img
                            alt="View on Instagram" src="/img/social_icons/insta.png"
                            style={{display: 'inline'}}/>
                    </a>
                </span>
                
            </div>
          
        )
    }  

}

export default PeoplesJewelersFooter
