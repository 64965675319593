import { connect } from 'react-redux'
import { state_update } from '../actions'
import AiImageBig from './AiImageBig'
import imageLogic from '../reducers/imageLogic'
import { INITIAL_ZALES_IMG_URL,INITIAL_KAY_IMG_URL,INITIAL_KAY_IMG_URL2,DEFAULT_K_QSKU,DEFAULT_K_QSKU2,DEFAULT_J_QSKU,DEFAULT_J_QSKU2}  from '../constants'

function getViews (state,size){
  //console.log('Big Image',state)
  //if (state['zalessku'] !== ''){return ['']}
  if (state['initial'] === 'yes'){
    if (window.currentSite ==='zales')
      return [INITIAL_ZALES_IMG_URL]
    else  
      { if ([,DEFAULT_K_QSKU2,DEFAULT_J_QSKU,DEFAULT_J_QSKU2].includes(state['initial-qsku']))// === DEFAULT_KJ_QSKU2)
      {
      ////console.log('Image',INITIAL_KAY_IMG_URL)
        return [INITIAL_KAY_IMG_URL2]
      }
      else 
      {
      ////console.log('Image2',INITIAL_KAY_IMG_URL2,state['initial'],DEFAULT_KJ_QSKU)
      return [INITIAL_KAY_IMG_URL]
    }
  }
  }
  let slide_init=[]
     for (let i=1;i<=state['max_view'];i++) {
      // let extra_label = ''
      // if (state.hasOwnProperty('extra_label_view_' + i.toString()) ){
      //   extra_label='&extra='+state['extra_label_view_' + i.toString()]
      // } 
      slide_init.push(imageLogic(state,i)+'&s='+size.toString() ) 
     }
    ////console.log('url done',slide_init,state)
  return slide_init    
}
const mapStateToProps = (state, ownProps) => { 
  //let cur_image_url
  //let sku

  //let cur_image_url =imageLogic(state);
  ////console.log('url done',state['sku'])
  return {
    max_views: state['max_view'],
    active_view: state['active_view'] -1 ,
    view_image_base: imageLogic(state),
    slidesZoom:getViews(state,1000),
    slides:getViews(state,500),
    orientation:ownProps.orientation,
    pushOpenBox:state.pushOpenBox === true ? true : false,
    initial: state['initial']
  }
}

 

const mapDispatchToProps = (dispatch, ownProps) => ({
  onChange: ( (value) => {
    ////console.log('new active view',value)
    dispatch(state_update({attr:'active_view',value:(value+1)}))
  }),
onCloseOpenBox: ( () => {
      ////console.log('close OpenBox')
      dispatch(state_update({attr:'pushOpenBox',value:false}))
    }),
  onOpenOpenBox: ( () => {
      ////console.log('close OpenBox')
      dispatch(state_update({attr:'pushOpenBox',value:true}))
    })
 
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(AiImageBig)