import {Component} from "react";
import Footer from "../footer";
import React from "react";
import {MyMixin} from "../footer"



class SubscriptionForm extends MyMixin(Component) {

    constructor(props) {
        super(props);
        this.state = {email: '', isSubmitSuccess: false};

        this.onSubmit = this.onSubmit.bind(this);
        this.onEmailChange = this.onEmailChange.bind(this);
    }

    onSubmit(event) {
        if (this.state.email)
            this.setState({isSubmitSuccess: true});
        event.preventDefault();
    }

    onEmailChange(event) {
        this.setState({email: event.target.value});
    }

    render() {
        super.render();
        return (
            <div className="join-list text-center pull-left" style={this.styleHidden}>
                
            </div>
        )
    }
}

class ZalesFooter extends MyMixin(Component) {

    siteDomain = 'ZALES.COM';

    getSocialIcons() {
        return (
            <ul style={this.styleHidden} className="social no-padding">
                <li>
                    <a href="https://www.facebook.com/Zales">
                        <img
                            alt="View Zales on Facebook"
                            src="/img/social_icons/facebook.png"
                            style={{display: 'inline'}}/>
                    </a>
                </li>
                <li>
                    <a href="https://twitter.com/ZalesJewelers">
                        <img
                            alt="View Zales on Twitter" src="/img/social_icons/twitter.png"
                            style={{display: 'inline'}}/>
                    </a>
                </li>
                <li>
                    <a href="https://plus.google.com/+zales/posts">
                        <img 
                            alt="View Zales on Google Plus"
                            src="/img/social_icons/gplus.png" style={{display: 'inline'}}/>
                    </a>
                </li>
                <li>
                    <a href="https://www.pinterest.com/zalesjewelers/">
                        <img
                            alt="View Zales on Pinterest"
                            src="/img/social_icons/pinterest.png"
                            style={{display: 'inline'}}/>
                    </a>
                </li>
                <li>
                    <a href="https://www.instagram.com/zalesjewelers/">
                        <img
                            alt="View Zales on Instagram" src="/img/social_icons/insta.png"
                            style={{display: 'inline'}}/>
                    </a>
                </li>
                <li>
                    <a href="https://www.youtube.com/zales">
                        <img
                            alt="View Zales on YouTube" src="/img/social_icons/youtube.png"
                            style={{display: 'inline'}}/>
                    </a>
                </li>
            </ul>
        )
    }
    getShopOutlet(){
        return (
                    <div style={{fontSize: '10px', float: 'right', marginLeft: '-40px', marginTop: '0px', zIndex: '1024'}}><a
                        style={{color: '#39607A', textDecoration: 'none'}}
                        href="https://www.zalesoutlet.com"> Also Shop &nbsp;&nbsp;<img
                        className="ico-zales-outlet" alt="&nbsp;&nbsp;Also Shop Zales Outlet"
                        src={"https://static.thepersonalizedbest.com/header/zales-outlet.png"}
                        style={{display: 'inline', border: 'none', width: 'auto', height: 'auto'}}/></a>
                     </div>
        )
    }
    getSocialIconsDiv() {
        return (
            <div style={this.styleHidden} className="social" >
                
                <span style={{ height: '41px', marginLeft:'8px', zIndex: '1000'}}>
                    <a href="https://www.facebook.com/Zales">
                        <img
                            alt="View Zales on Facebook"
                            src="/img/social_icons/facebook.png"
                            style={{display: 'inline'}}/>
                    </a>
                </span>
                <span style={{ height: '41px', marginLeft:'10px', zIndex: '1000' }}>
                    <a href="https://twitter.com/ZalesJewelers">
                        <img
                            alt="View Zales on Twitter" src="/img/social_icons/twitter.png"
                            style={{display: 'inline'}}/>
                    </a>
                </span>
              
                <span style={{ height: '41px', marginLeft:'-148px', marginTop: '50px', zIndex: '1000', }}>
                    <a href="https://www.pinterest.com/zalesjewelers/">
                        <img
                            alt="View Zales on Pinterest"
                            src="/img/social_icons/pinterest.png"
                            style={{display: 'inline'}}/>
                    </a>
                </span>
                <span style={{ height: '41px', marginLeft:'10px', marginTop: '50px', zIndex: '1000'}}>
                    <a href="https://www.instagram.com/zalesjewelers/">
                        <img
                            alt="View Zales on Instagram" src="/img/social_icons/insta.png"
                            style={{display: 'inline'}}/>
                    </a>
                </span>
                <span style={{ height: '41px', marginLeft:'10px', marginTop: '50px', zIndex: '1000'}}>
                    <a href="https://www.youtube.com/zales">
                        <img
                            alt="View Zales on YouTube" src="/img/social_icons/youtube.png"
                            style={{display: 'inline'}}/>
                    </a>
                </span>
               
            </div>
          
        )
    }


    render() {
        super.render();
        return (
            <div className="footer-container">
                <div style={{maxWidth: '1400px', margin: '10px auto'}}>
                    <SubscriptionForm/>
                  
                    <Footer model={this.props.model} isSmallSize={window.innerWidth < 760}/>
                     {this.getShopOutlet()}
                     {this.getSocialIconsDiv()}
                  
                </div>
                <div className="footer-base">
                    <div className="container no-padding" style={{maxWidth: '1400px', margin: '0 auto'}}>
                        ©{this.siteDomain}
                        <span className={!this.isMobileLayout ? "pull-right" : ""}>
                    <a href={window.baseSiteUrl + "/privacy-policy"}>
                        &nbsp;&nbsp;
                        Privacy Policy</a>
                    <span className="hide-mob">&nbsp;&nbsp;&nbsp; | &nbsp;&nbsp;&nbsp;</span>
                    <a href={window.baseSiteUrl + "/terms-and-conditions"}>Terms and Conditions</a>
                    <span className="hide-mob">&nbsp;&nbsp;&nbsp; | &nbsp;&nbsp;&nbsp;</span>
                    <a href={ window.baseSiteUrl + "/sitemap"}>Sitemap</a>
                </span>
                    </div>
                </div>
            </div>
        )
    }
}

export default ZalesFooter
