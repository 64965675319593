import {Component} from "react";
import Footer from "../footer";
import React from "react";


class KayOutletFooter extends Component {
    toogleWidth = 760;
    isMobileLayout = false;

    render() {
        let styleHidden = {};
        this.isMobileLayout = window.innerWidth < this.toogleWidth;
        if (this.isMobileLayout)
            styleHidden = {display: 'none'};
        return (
            <div className="kayoutlet-footer">
                <hr/>
                <div className="ui-g">
                  
                   
                    <div className={!this.isMobileLayout ? "ui-grid-col-8" : "ui-grid-col-12"}>
                  
                        <Footer model={this.props.model} isSmallSize={window.innerWidth < this.toogleWidth}
                                uiGridClasses="ui-g-12 ui-md-6 ui-lg-3"/>
                    </div>
                    <div className="ui-grid-col-2" style={styleHidden}>
                        <nav className="footer-nav--header">
                            <div className="header-wrap row xlarge-collapse">
                                <div className="footer-email-signup-1">
                                <div style={{marginTop: '27px'}}><div className="footer-category">
                                        <div className="footer-item">
                                            <a className="ei-widget"><span style={{fontSize:'0.7rem'}}>FOLLOW US</span></a>
                                        </div>
                                        </div>
                                        <div className="footer-spliter"></div>
                                    </div>
                                    <ul className="footer-social-icons">
                                        <li>
                                            <a href="https://www.facebook.com/KayJewelers">
                                                <img alt="" src="/img/social_icons/facebook.png" style={{display: 'inline', width: '33px', height: '33px'}}/></a></li>
                                        <li>
                                            <a href="https://www.twitter.com/KayJewelers">
                                                <img alt="" src="/img/social_icons/twitter.png" style={{display: 'inline', width: '33px', height: '33px'}}/></a></li>
                                        <li>
                                            <a href="https://www.youtube.com/kayjewelers">
                                                <img alt="" src="/img/social_icons/youtube.png" style={{display: 'inline', width: '33px', height: '33px'}}/></a></li>
                                        <li>
                                             <a href="https://www.pinterest.com/kayjewelers">
                                                <img alt="" src="/img/social_icons/pinterest.png" style={{display: 'inline', width: '33px', height: '33px'}}/>
                                             </a>   
                                        </li>
                                        </ul>
                                    
                                </div>
                            </div>
                        </nav>
                    </div>

                </div>

                <div>
                        {this.isMobileLayout?
                                <div>
                                 <ul className="footer-social-icons-1">
                                 <li>
                                            <a href="https://www.facebook.com/KayJewelers">
                                                <img alt="" src="/img/social_icons/facebook.png" style={{display: 'inline', width: '33px', height: '33px'}}/></a></li>
                                        <li>
                                            <a href="https://www.twitter.com/KayJewelers">
                                                <img alt="" src="/img/social_icons/twitter.png" style={{display: 'inline', width: '33px', height: '33px'}}/></a></li>
                                        <li>
                                            <a href="https://www.youtube.com/kayjewelers">
                                                <img alt="" src="/img/social_icons/youtube.png" style={{display: 'inline', width: '33px', height: '33px'}}/></a></li>
                                        <li>
                                             <a href="https://www.pinterest.com/kayjewelers">
                                                <img alt="" src="/img/social_icons/pinterest.png" style={{display: 'inline', width: '33px', height: '33px'}}/>
                                             </a>   
                                        </li>
                                        </ul>
                                        
                                        </div>: null
                    }</div>




                <div className="footer-copyright-row__wrapper">
                    <div className="footer-copyright-row clearfix" style={{margin: "auto"}}>
                        <p></p>
                        <div>
                        <ul style={!this.isMobileLayout ? {display: 'flex',marginTop: '10px'} : {}}>
                            <li style={{ fontSize: '10px', marginTop: '10px'}}>©Sterling Jewelers Inc. dba KAY Jewelers&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</li>
                            <li style={{ fontSize: '10px', marginTop: '10px'}}><img alt="" src="//www.kay.com/_ui/v0051/responsive/theme-kay/images/RJC_Logo.jpeg" style={{display: 'inline', width: '22px', height: '32px'}}/> </li>
                            <li style={{ fontSize: '10px', marginTop: '10px'}}><a className="hero__link " href="https://www.kay.com/privacy-policy?icid=FOOTER:PrivacyPoilcy"
                                   target="_top" title="Privacy Policy"> Privacy Policy</a></li>
                            <li style={{ fontSize: '10px', marginTop: '10px'}}><a className="hero__link "
                                   href="https://www.kay.com/kay-legal-disclaimers?icid=FOOTER:TermsCond" target="_top"
                                   title="Terms of Use">&nbsp;Terms of Use </a></li>
                            <li style={{ fontSize: '10px', marginTop: '10px'}}><a className=""
                                   href="https://www.kay.com/sitemap"
                                   target="_top">Site Map </a></li>
                            
                        </ul>
                        </div>
                    </div>
                </div>
                
            </div>
        )
    }
}

export default KayOutletFooter;