import {
  includeRule,
  excludeRule,
  hideOptionSet
}
from './rules_functions.js';

  
  
export function rules_ben(newState, state) {
  //rules with 4 conditions:
  var newrule = includeRule(newState, state, 'ben')
  var  show  = hideOptionSet(newState, state, [ ['band', ['0']]])
  if (show == true)  show  = hideOptionSet(newState, state, [ ['sh', [ '5']]])
  if (show == true)  show  = hideOptionSet(newState, state, [ ['bset', ['UP','PR','PV']]])
  
  newrule['show']  = show
    return newrule
}


