import { connect } from 'react-redux'
//import { state_update } from '../actions'
//import LeftPanel from './LeftPanel'
import RightPanel from './RightPanel';
//import { state_start_over } from '../actions'

const mapStateToProps = (state, ownProps) => ({
  showSticky:state['showSticky'] ? false: true
  
})
  
 

const mapDispatchToProps = (dispatch, ownProps) => ({
//  onStartOver: ( () =>{////console.log('start_over'); dispatch(state_start_over())})
  //onClick: ( () => dispatch(state_update({attr:ownProps.attr,value:ownProps.value})))
//  onClick: ( () => dispatch(state_update({attr:'review',value:false})))
 // onClick: () =>state_update({attr:ownProps.attr,value:ownProps.value})
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(RightPanel)