import {Component} from "react";
import Favicon from "react-favicon";
import React from "react";
import ZalesHeader from "./zales"
import JaredHeader from "./jared"
import KayHeader from "./kay"

import PeoplesJewelersHeader from './peoplesjewelers'
import ZalesOutletHeader from './zalesoutlet'
import KayOutletHeader from "./kayoutlet";

class HeaderSwitchComponent extends Component {

    constructor(props) {
        super(props);
        this.state = {items: []}

    }

    componentDidMount() {
        fetch('/' + window.currentSite + '.navbar.json')
            .then(response => {
                if (response.ok) {
                    return response.text()
                }
                else {
                    this.props.messages.show({
                        severity: 'error',
                        sticky: true,
                        summary: 'Error loading navbar data file: ',
                        detail: 'Cannot load data'
                    });
                    throw new Error('Something went wrong ...');
                }
            })
            .then(response => {
                try {
                    var src1 ='www.'+ window.currentSite +'.com'
                    var dst1 = window.currentEnv+'.'+ window.currentSite +'.com'
                    if (window.currentEnv !== "prod")
                        response = response.replace(new RegExp( src1, 'gi'), dst1);                    
                    return JSON.parse(response);
                } catch (err) {
                    this.props.messages.show({
                        severity: 'error',
                        sticky: true,
                        summary: 'Error loading navbar data file: ',
                        detail: err.message
                    });
                }
            })
            .then(data => { 
           
            this.setState({items: data})})
    }

    render() {
        let Component = null;
       

        if (this.state && this.state.items) {
            let headerComponents = {
                kay: KayHeader,
                zales: ZalesHeader,
                jared: JaredHeader,
           
                peoplesjewellers: PeoplesJewelersHeader,
                zalesoutlet: ZalesOutletHeader,
                kayoutlet: KayOutletHeader
            };
            Component = headerComponents[window.currentSite];
        }

        return (
            <div>
                <Favicon url={"/img/" + window.currentSite + "_favicon.ico"}/>
                <Component model={this.state.items}/>
            </div>

        )
    }
}

export default HeaderSwitchComponent;
