
import {
    includeRule,
    excludeRule
  }
  from './rules_functions.js';

  
export function rules_band(newState, state) {
    //rules with 4 conditions:
    var newrule = includeRule(newState, state, 'band')
        ////console.log('ALL Include rules', newrule)
    newrule = excludeRule(newState, state, [
       // ['head', ['H1','S1','P1','H2','B1','C1','T1TSPR','T1TSR','T1TSTR','T1TSO','T1TSP','TH2TSR','TH2TSTR','TH2TSP']],
        ['sh', ['1', '5',  '10','15']],
        ['band', ['C']]], newrule)
    newrule['show']  = true
      return newrule
}