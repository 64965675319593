
export const state_update = (object) => ({
  type: 'UPDATE',
  attr: object.attr,
  value: object.value
})
export const state_force_update = (object) => ({
  type: 'FORCE_UPDATE',
  attr: object.attr,
  value: object.value
})
export const state_load_json = (object) => ({
  type: 'LOAD_JSON',
  object:object
})
export const state_load_zsku = (object) => ({
  type: 'ZALESSKU',
  object:{value:object}
})
export const state_load_bs = (object) => ({
  type: 'BESTSELLER',
  object: {value:object}
})
export const state_start_over = () => ({
  type: 'START_OVER'
})
export const state_history = (object) => ({
  type: 'HISTORY',
  attr: object.attr,
  value: object.value
})

