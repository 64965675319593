
import {
    includeRule,
    excludeRule,hideOptionSet
  }
  from './rules_functions.js';

  
export function rules_eb1(newState, state) {
    //rules with 4 conditions:
    var newrule = includeRule(newState, state, 'eb1')
        ////console.log('ALL Include rules', newrule)
    var show  = hideOptionSet(newState, state, [ ['band', ['0']]])
   
  newrule['show']  = show
      return newrule
}