
import {
    includeRule,
    excludeRule
  }
  from './rules_functions.js';
  
  
export function rules_sh(newState, state) {
  //rules with 4 conditions:
  var newrule = includeRule(newState, state, 'sh')
      ////console.log('ALL Include rules', newrule)   
    newrule = excludeRule(newState, state, [
        ['head', [ "T1TSR", "T1TSP", "T1TSTR", "T1TSPR", "P1",'B1','H2']],
        ['sh', ['15']]], newrule)
    newrule = excludeRule(newState, state, [
            ['head', [ "T1TSR", "T1TSP", "T1TSTR", "T1TSPR"]],
            ['sh', ['16']]], newrule)  
    newrule = excludeRule(newState, state, [
        ['ce', ['D','LD','UD']],
       // ['ds', ['R', 'C', 'O']],
       // ['dctw', ['0.25', '0.33', '0.5', '0.75', '1']],
        ['head', ['T1TSPR']],
        ['sh', ['2', '4']]], newrule)
    
   
    newrule = excludeRule(newState, state, [
        ['ce', ['D','LD','UD']],
        ['ds', [ 'P', 'E']],
        ['dctw', ['0.25', '0.33']],
        ['head', ['T1TSTR']],
        ['sh', ['2', '4']]], newrule)

    newrule['show']  = true
    return newrule
}