import ZalesFooter from "./zales"


class ZalesOutletFooter extends ZalesFooter {
    siteDomain = 'ZALESOUTLET.COM'


    

    getShopOutlet(){
        return null
    }




}

export default ZalesOutletFooter
