import React, { Component } from 'react';
import AiInputTextWrap from './AiInputTextWrap';

  

//const AiInput = ({ dispatch }) => {
class AiInput extends Component {
  
  render() {
   ////console.log('RENDER Input',this.props.loading)
   var rows = [];
  ////console.log('Start constr InputSet',this.props.product,this.props) 
   if (this.props.optionE !== undefined) {
   var children = this.props.optionE.options
    for (let i in children) {
  ////console.log('constr InputSet',this.props.product,children) 
    // if (children[i].show)
      let view = '1'
      if (children[i].hasOwnProperty('switch_view')) {
        view = children[i].switch_view
      }

      rows.push(<AiInputTextWrap 
      id={children[i].attr}  
      name={children[i].name} 
      attr={children[i].attr} 
      value={children[i].value} 
      show={children[i].show} 
      product={this.props.product} 
      filter={this.props.optionE.filter} 
      switchView={view} 
      key={i.toString()} 
      maxSliderSize={this.props.maxSliderSize}
      options_all={this.props.options_all}
      maxLength={children[i].maxLength} />);

    }
   }
   
  let fcolor ='ai-color-' + window.currentSite +' ai-option-title'

   return (
   
    <div  className='ai-input-box'>
      <div className={fcolor}>{this.props.name.toUpperCase()}</div>
      
    {rows}
    
    </div>
);
  }
}
export default AiInput;
